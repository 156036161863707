<template>
  <validation-provider
    v-slot="validationContext"
    ref="formType"
    rules="required"
    name="Type"
  >
    <b-form-group
      label="Type"
      label-for="form-type"
    >
      <v-select
        id="form-type"
        v-model="selectedFormType"
        label="name"
        placeholder="Select the Form type"
        :options="formTypesDisplay"
        :state="
          getValidationState(
            validationContext
          )
        "
      />
      <b-form-invalid-feedback
        :state="
          getValidationState(
            validationContext
          )
        "
      >
        {{
          validationContext.errors[0]
        }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
import vSelect from "vue-select";
import { formTypesDisplay } from '@models/formTypes';
import {  
  BFormGroup,
  BFormInvalidFeedback
} from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import {
  getValidationState
} from "@/libs/utils";
import _isEqual from 'lodash/isEqual';
export default {
  components: {
    vSelect,
    BFormGroup,
    ValidationProvider,
    BFormInvalidFeedback
  },
  props:{
    formType: {
      type: Object,
      default: () => {}
    }
  },
  data(vm) {
    return {
      selectedFormType: vm.formType,
      formTypesDisplay
    };
  },
  watch:{
    formType() {
      this.selectedFormType = this.formType;
    },
    selectedFormType(n, o){
      if(_isEqual(n, o)) {
        return;
      }
      if(n) {
        const {id , name} = n;
        this.$emit('update', {id, name});
      }
    }
  },
  setup() {
    return {
      getValidationState
    };
  }
};
</script>
