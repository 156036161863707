<template>
  <div class="col-sm-12">
    <text-box-question-component
      :index="index"
      :title="questionTitle"
      :question-text="questionText"
      @updateTitle="updateQuestionTitle"
      @updateQuestionText="updateQuestionText"
    />
      
    <b-row class="mt-1">
      <b-col md="7">
        <label class="mt-1"> Information text </label>
      </b-col>
      <b-col md="5">
        <label class="mt-1 float-right"> Character Limit - {{ questionCommon.answer_character_limit }} </label>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <single-text-box-answer
          :index="index"
          :prefilled-answer="questionPrefilledAnswer"
          @update="updatePreFilledAnswer"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, VBModal 
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import  TextBoxQuestionComponent  from "@/views/components/questions/TitleQuestionComponent.vue";
import { questionTypes } from '@models/questionTypes';
import { mapMutations, mapGetters } from 'vuex';
import SingleTextBoxAnswer from './SingleTextBoxAnswer.vue';
export default {
  name: 'SingleTextBoxQuestionForm',
  components: {
    BRow,
    BCol,
    TextBoxQuestionComponent,
    SingleTextBoxAnswer
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    localeId: {
      type: Number,
      default: 0
    },
    questionOrder: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isUpdating: false
    };
  },
  computed: {
    ...mapGetters('questions', ['questionCommon']),
    questionTitle() {
      return this.questionCommon?.translations.filter(t => t.locale_id === this.localeId).shift()?.title || '';
    },
    questionText() {
      return  this.questionCommon?.translations.filter(t => t.locale_id === this.localeId).shift()?.question_text || '';
    },
    questionPrefilledAnswer() {
      return  this.questionCommon?.translations.filter(t => t.locale_id === this.localeId).shift()?.prefilled_answer || '';
    }
  },
  created() {
    if(this.questionOrder !== 0) {
      this.UPDATE_QUESTION({
        answer_character_limit: 500,
        type_id: questionTypes.TEXT,
        question_order: this.questionOrder
      });
    } else {
      this.UPDATE_QUESTION({
        answer_character_limit: 500,
        type_id: questionTypes.TEXT,
      });
    }
    
  },
  methods: {
    updateQuestionTitle (title) {
      const trans = {title, locale_id: this.localeId };
      this.UPDATE_QUESTION_TRANS(trans);
    },
    updateQuestionText (text) {
      const trans = {question_text: text, locale_id: this.localeId };
      this.UPDATE_QUESTION_TRANS(trans);
    },
    updatePreFilledAnswer (text) {
      const trans = {prefilled_answer: text, locale_id: this.localeId };
      this.UPDATE_QUESTION_TRANS(trans);
    },
    ...mapMutations('questions',
      ['ADD_QUESTION', 'UPDATE_QUESTION','CLEAR_QUESTION', 'UPDATE_QUESTION_TRANS']
    )
  }
};
</script> 

<style scoped>
  .fl-r{
    float: right;
  }
</style>