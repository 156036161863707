<template>
  <b-card>
    <b-row>
      <b-col
        sm="2"
        offset="10"
      >
        <add-question-button
          @addQuestionType="showQuestionAdd"
        />
      </b-col>
    </b-row>
    <add-question-side-bar
      :question-type="questionTypeComp"
      :open="openAdd"
      :title="sideBarTitle"
      :is-matching-question="isMatchingQuestion"
      :is-updating="isUpdating"
      @edit="editQuestion"
      @editMatching="editMatchingQuestion"
      @add="reloadList = true"
      @toggleSideBar="toggleAddSideBar"
      @submit="createLibraryQuestion"
      @submitMatching="createLibraryMatchedPair"
    />
    <edit-question-side-bar
      :question-type="questionType"
      :question-id="questionId"
      :open="openEdit"
      :is-updating="isUpdating"
      @update="updateLibraryQuestion"
      @updateMatching="updateApplicationSet"
      @toggleSideBar="toggleEditSideBar"
    />
    <questions-list
      ref="questionList"
      :reload="reloadList"
      @edit="editQuestion"
      @editMatching="editMatchingQuestion"
      @delete="deleteQuestion"
      @deleteMatching="deleteMatchingQuestion"
    />
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard
} from "bootstrap-vue";

import QuestionsList from "./QuestionsList.vue";
import Ripple from 'vue-ripple-directive';
import AddQuestionSideBar from './AddQuestionSideBar.vue';
import EditQuestionSideBar  from './EditQuestionSideBar.vue';
import { mapMutations } from 'vuex';
import questionsService from '@/services/questionsService';
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import AddQuestionButton from '@components/questions/AddQuestionButton.vue';
import { questionTypes } from '@models/questionTypes';

export default {
  name: 'AdminQuestion',
  components: {
    BCard,
    QuestionsList,
    AddQuestionSideBar,
    EditQuestionSideBar,
    AddQuestionButton,
    BRow,
    BCol
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      openEdit: false,
      openAdd: false,
      questionType: undefined,
      sideBarTitle: '',
      questionId: undefined,
      reloadList: false,
      questionTypeComp: undefined,
      isMatchingQuestion: false,
      isUpdating: false
    };
  },
  methods: {
    toggleEditSideBar(value) {
      this.clearForm();
      this.openEdit = value;
    },
    toggleAddSideBar(value) {
      this.clearForm();
      this.openAdd = value;
    },
    editQuestion(id) {
      this.openEdit = true;
      this.questionId = id;
      this.questionType = undefined;
    },
    editMatchingQuestion(payload) {
      const { id, qType } = payload;
      this.openEdit = true;
      this.questionId = id;
      this.questionType = qType;
    },
    async deleteQuestion(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this question ?', {
          title: 'Delete Question',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            try {
              this.isUpdating = true;
              await questionsService.deleteQuestion(id);
              this.$toast(makeSuccessToast('Question deleted successfully.'));
              this.$refs.questionList.loadItems();
            } catch (e) {
              const { data } = e.response;
              this.$toast(makeErrorToast(data.message));
              this.$log.error(e);
            } finally {
              this.isUpdating = false;
            }
          }
        });
    },
    async deleteMatchingQuestion(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this question ?', {
          title: 'Delete Question',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            try {
              this.isUpdating = true;
              await questionsService.deleteMatchQuestion(id);
              this.$toast(makeSuccessToast('Question deleted successfully.'));
              this.$refs.questionList.loadItems();
            } catch (e) {
              const { data } = e.response;
              this.$toast(makeErrorToast(data.message));
              this.$log.error(e);
            } finally {
              this.isUpdating = false;
            }
          }
        });
    },
    showQuestionAdd (qType) {
      const {type} = qType;
      if (type.value === questionTypes.TEXT) {
        this.questionTypeComp = 'AddSingleTextBoxQuestionForm';
        this.sideBarTitle = 'Add New Question - Single Text Box';
        this.openAdd = true;
      }
      if (type.value === questionTypes.CHOICE) {
        this.questionTypeComp = 'AddMultipleChoiceQuestionForm';
        this.sideBarTitle = 'Add New Question - Multi Choice';
        this.openAdd = true;
      }
      if (type.value === questionTypes.STATEMENT) {
        this.questionTypeComp = 'AddRatingScalesQuestionForm';
        this.sideBarTitle = 'Add New Question - Rating Scale';
        this.openAdd = true;
      }
      if (type.value === questionTypes.MATCHING) {
        this.questionTypeComp = 'AddMultipleChoiceQuestionForm';
        this.sideBarTitle = 'Add New Question - Matching';
        this.openAdd = true;
        this.isMatchingQuestion = true;
      }
    },
    async createLibraryQuestion(data) {
      const { question } = data;
      try {
        this.isUpdating = true;
        await questionsService.postCreateQuestion(question);
        this.reloadList = true;
        this.$refs.questionList.loadItems();
        this.$toast(makeSuccessToast('Question created successfully.'));
        this.clearForm();
      } catch(e) {
        const { data } = e.response;
        this.$log.error(e);
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.isUpdating = false;
      }
    },
    async updateLibraryQuestion(data) {
      const { question } = data;
      const { id } = question;
      const filterArray = this.cleanTranlations(question);
      try {
        this.isUpdating = true;
        await questionsService.patchQuestion(id, filterArray);
        this.reloadList = true;
        this.$refs.questionList.loadItems();
        this.$toast(makeSuccessToast('Question updated successfully.'));
        this.clearForm();
      } catch(e) {
        const { data } = e.response;
        this.$log.error(e);
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.isUpdating = false;
      }
    },
    async createLibraryMatchedPair(question) {
      try {
        this.isUpdating = true;
        await questionsService.postCreateMatchedPair(question);
        this.reloadList = true;
        this.$refs.questionList.loadItems();
        this.$toast(makeSuccessToast('Question created successfully.'));
        this.clearForm();
      } catch(e) {
        const { data } = e.response;
        this.$log.error(e);
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.isUpdating = false;
      }
    },
    async updateApplicationSet(data) {
      const {question_matched_pair_id } = data.questions[0]; 
      const { weight , questions } = data; 
      try {
        this.isUpdating = true;
        await questionsService.patchMatchQuestion(question_matched_pair_id, {weight, questions});
        this.reloadList = true;
        this.$toast(makeSuccessToast('Question updated successfully.'));
        this.clearForm();
      } catch(e) {
        const { data } = e.response;
        this.$log.error(e);
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.isUpdating = false;
      }
    },
    clearForm() {
      this.CLEAR_QUESTION();
      this.openAdd = false;
      this.openEdit = false;
      this.isMatchingQuestion = false;
      this.questionId = undefined;
      this.sideBarTitle = '';
      this.questionTypeComp = undefined;
    },
    cleanTranlations(data) {
      // Removes choices or translations that have been added by clicking on Lang TAB
      const filteredChoices =  data.choices.map(c => ({ ...c, translations: c.translations.filter(t => t.choice_text && t.locale_id)}));
      const filteredTranslations = data.translations.filter(t =>t.title && t.question_text);
      const filteredStatements =  data.statements.map(s => ({ ...s, translations: s.translations.filter(t => t.question_text && t.locale_id)}));

      return {...data, choices:filteredChoices, translations:filteredTranslations, statements: filteredStatements };
    },
    ...mapMutations('questions',
      ['CLEAR_QUESTION']
    )
  }
};
</script>
