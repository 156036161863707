import client from './http';

class QuestionsService {

  constructor(axios) {
    this.axios = axios;
  }
  // Libary
  postCreateQuestion(question) {
    return this.axios.post(`/library/questions`, question);
  }
  getList(search) {
    return this.axios.get(`/library/questions`, { params: { search: search } });
  }
  getQuestionById(id) {
    return this.axios.get(`/library/questions/${id}`);
  }
  patchQuestion(id, question) {
    return this.axios.patch(`/library/questions/${id}`, question);
  }
  deleteQuestion(id) {
    return this.axios.delete(`/library/questions/${id}`);
  }
  // Match Questions Libary
  postCreateMatchedPair(questions) {
    return this.axios.post(`/library/questions/pairs`, questions);
  }
  deleteMatchQuestion(id) {
    return this.axios.delete(`/library/questions/pairs/${id}`);
  }
  patchMatchQuestion(id, question) {
    return this.axios.patch(`/library/questions/pairs/${id}`, question);
  }
  getMatchingQuestionById(id) {
    return this.axios.get(`/library/questions/pairs/${id}`);
  }

  //individual library application questions
  createLibraryFormQuestion(id, question) {
    return this.axios.post(`/library/applications/${id}/questions`, question);
  }
  updateLibraryFormQuestion(formId, questionId, question) {
    return this.axios.patch(`/library/applications/${formId}/questions/${questionId}`, question);
  }
  postAddQuestionToForm (formId, question) {
    return this.axios.post(`/library/applications/${formId}/questions/add`, question);
  }
  postAddQuestionToProgramForm (formId, programId, question) {
    return this.axios.post(`/library/applications/${formId}/questions/add`, question);
  }
  deleteLibraryFormQuestion(formId, questionId) {
    return this.axios.delete(`/library/applications/${formId}/questions/${questionId}`);
  }
  deleteLibraryFormMatchQuestion(applicationSetId, questionMatchedPairId) {
    return this.axios.delete(`/library/application-sets/${applicationSetId}/questions/pairs/${questionMatchedPairId}`);
  }

  //individual program application questions
  createProgramFormQuestion(programId, formId, question) {
    return this.axios.post(`/programs/${programId}/applications/${formId}/questions`, question);
  }
  updateProgramFormQuestion(programId, formId, questionId, question) {
    return this.axios.patch(`/programs/${programId}/applications/${formId}/questions/${questionId}`, question);
  }
  //individual program application matching questions
  postCreateProgramMatchedPair(programId, applicationSetId, questions) {
    return this.axios.post(`/programs/${programId}/application-sets/${applicationSetId}/questions/pairs`, questions);
  }
  patchProgramMatchQuestion(programId, applicationSetId, questionId, question) {
    return this.axios.patch(`/programs/${programId}/application-sets/${applicationSetId}/questions/pairs/${questionId}`, question);
  }
  readProgramFormQuestion(programId, formId, questionId) {
    return this.axios.get(`/programs/${programId}/applications/${formId}/questions/${questionId}`);
  }
  readProgramMatchingQuestion(programId, applicationSetId, questionId) {
    return this.axios.get(`/programs/${programId}/application-sets/${applicationSetId}/questions/pairs/${questionId}`);
  }
  deleteProgramFormMatchQuestion(programId, applicationSetId, questionMatchedPairId) {
    return this.axios.delete(`/programs/${programId}/application-sets/${applicationSetId}/questions/pairs/${questionMatchedPairId}`);
  }
  deleteProgramFormQuestion(programId, formId, questionId) {
    return this.axios.delete(`/programs/${programId}/applications/${formId}/questions/${questionId}`);
  }

}

const questionsService = new QuestionsService(client);
export default questionsService;
