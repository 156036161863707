<template>
  <b-row class="mt-1">
    <b-col
      v-if="showUserRoleField || showFormTypeField"
      md="6"
    >
      <user-roles-select
        v-if="showUserRoleField"
        list-type="participant"
        :value="questionCommon.target_roles"
        :form-id="formId"
        @update="updateUserRole"
      />
      <aom-form-type-select
        v-if="showFormTypeField"
        :form-type="questionCommon.target_form_type"
        @update="updateFormType"
      />
    </b-col>
    <b-col md="6">
      <question-tool-bar
        :index="index"
        :is-required="questionCommon.is_required"
        :is-summarisable="questionCommon.is_summarisable"
        :is-visible="questionCommon.is_visible"
        :is-repeatable="questionCommon.is_repeatable"
        :is-locked="questionCommon.is_locked"
        :is-matching-question="isMatchingQuestion"
        :form-type="formType"
        @updateIsRequired="updateIsRequired"
        @updateIsSummarisable="updateIsSummarisable"
        @updateIsVisible="updateIsVisible"
        @updateIsRepeatable="updateIsRepeatable"
        @updateIsLocked="updateIsLocked"
      />
    </b-col>
    <b-col
      v-if="isMatchingQuestion"
      md="6"
    >
      <question-tool-bar
        :index="index"
        :is-matching-question="isMatchingQuestion"
        :is-visible="multiChoiceMatchingQuestion.is_visible"
        @updateIsVisible="updateMatchingIsVisible"
      />
    </b-col>
  </b-row>  
</template>

<script>
import {
  BRow, BCol, VBModal
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import UserRolesSelect from '@/@aom-core/UserRolesSelect.vue';
import AomFormTypeSelect from '@/@aom-core/AomFormTypeSelect.vue';
import { RolesClass } from '@models/userRolesClass';
import QuestionToolBar from './QuestionToolBar.vue';
import { mapMutations, mapGetters } from 'vuex';
import { formTypes } from '@models';
 
export default {
  name: 'QuestionCommon',
  components: {
    BRow,
    BCol,
    UserRolesSelect,
    AomFormTypeSelect,
    QuestionToolBar
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    isMatchingQuestion: {
      type: Boolean
    },
    userRoles: {
      type: Array,
      default: () => []
    },
    formType: {
      type: Object,
      default: () => {}
    },
    formId: {
      type: [Number, String],
      default: 0
    }
  },
  inject: ['localeId'],
  data() {
    return {};
  },
  computed: {
    ...mapGetters('questions', ['questionCommon', 'questionFormType', 'multiChoiceMatchingQuestion']),
    isSurveyForm () {
      return this.formType && (this.formType.id === formTypes.SURVEY);
    },
    showUserRoleField() {
      return !this.isMatchingQuestion && !this.isSurveyForm;
    },
    showFormTypeField() {
      return !this.isMatchingQuestion && (!this.formType || !this.formType.id);
    }
  },
  watch: {
    userRoles: {
      handler(n) {
        if(n) {
          this.updateUserRole(n);
        }
      },
      immediate: true,
      deep: true
    },
    formType: {
      handler(n) {
        if(n) {
          this.updateFormType(n);
        }
      },
      immediate: true,
      deep: true
    },
    formId: {
      handler(n) {
        if(n) {
          this.updateFormId(n);
        }
      },
      immediate: true,
    }
  },
  methods: {
    updateUserRole (role) {
      if(role.length > 0) {
        const targetRoles = role.map(r => new RolesClass({ id: r.id, name: r.name }));
        this.UPDATE_QUESTION({target_roles: targetRoles});
      }
    },
    updateFormType (type) {
      this.UPDATE_QUESTION({
        target_form_type: {
          id:type.id,
          name: type.name
        },
        target_form_type_id: type.id
      });
    },
    updateFormId (formId) {
      this.UPDATE_QUESTION({form_id: formId});
    },
    updateIsRequired (isRequired) {
      this.UPDATE_QUESTION({is_required: isRequired});
    },
    updateIsSummarisable (isSummarisable) {
      this.UPDATE_QUESTION({is_summarisable: isSummarisable});
    },
    updateIsVisible (isVisible) {
      this.UPDATE_QUESTION({is_visible: isVisible});
    },
    updateMatchingIsVisible(isVisible) {
      this.UPDATE_MATCHING_QUESTION({is_visible: isVisible});
    },
    updateIsRepeatable (isRepeatable) {
      this.UPDATE_QUESTION({is_repeatable: isRepeatable});
    },
    updateIsLocked (isLocked) {
      this.UPDATE_QUESTION({is_locked: isLocked});
    },
    ...mapMutations('questions',['UPDATE_QUESTION', 'UPDATE_MATCHING_QUESTION'])
  }
};
</script> 

<style scoped>
  .fl-r{
    float: right;
  }
</style>