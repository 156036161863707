<template>
  <div>
    <b-row class="mt-2">
      <b-col md="12">
        <validation-provider
          v-slot="validationContext"
          :ref="`title-${validationProviderId}`"
          :vid="`title-${validationProviderId}`"
          :rules="{ required: isRequired }"
          name="Title"
        >
          <b-form-group 
            label="Title"
            label-for="title"
          >
            <b-form-input
              v-model="selectedTitle"
              type="text"
              placeholder="Title"
              :state="
                getValidationState(
                  validationContext
                )
              "
            />
            <b-form-invalid-feedback
              :state="
                getValidationState(
                  validationContext
                )
              "
            >
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>

    <b-row class="mt-1">
      <b-col md="12">
        <validation-provider
          v-slot="validationContext"
          :ref="`question-${validationProviderId}`"
          :vid="`question-${validationProviderId}`"
          :rules="{ required: isRequired }"
          name="Question"
        >
          <b-form-group 
            label="Question"
            label-for="question"
          >
            <b-form-textarea
              v-model="selectedQuestionText"
              placeholder="Question"
              maxlength="500"
              rows="3"
              :state="
                getValidationState(
                  validationContext
                )
              "
            />
            <b-form-invalid-feedback
              :state="
                getValidationState(
                  validationContext
                )
              "
            >
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import {
  BRow, 
  BCol, 
  BFormGroup, 
  BFormInput,
  BFormTextarea,
  BFormInvalidFeedback
} from 'bootstrap-vue';
import { ValidationProvider } from "vee-validate";
import { getValidationState } from "@/libs/utils";

export default {
  name:'TitleQuestionComponent',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea, 
    ValidationProvider,
    BFormInvalidFeedback
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    questionText: {
      type: String,
      default: ''
    },
    sortOrder: {
      type: Number,  
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    isMatchingQuestion: {
      type: Boolean,
    }
  },
  inject: ['localeId', 'currentTab'],
  data(vm) {
    return {
      selectedTitle: vm.title,
      selectedQuestionText: vm.questionText,
    };
  },
  computed: {
    isRequired() {
      let isTabDataDirty = false;
      for (const [, text] of Object.entries(this.$refs)) {
        if(text.value) {
          isTabDataDirty = true;
        }
      }
      return this.index === this.currentTab.value || isTabDataDirty;
    },
    validationProviderId (){
      const array = new Uint32Array(3);
      const id = window.crypto.getRandomValues(array);
      return this.isMatchingQuestion? id[0] : this.index;
    }
  },
  watch:{
    title(n) {
      this.selectedTitle = n;
    },
    questionText(n){
      this.selectedQuestionText = n;
    },
    selectedTitle(){
      this.$emit('updateTitle', this.selectedTitle);
    },
    selectedQuestionText(){
      this.$emit('updateQuestionText', this.selectedQuestionText);
    }
  },
  setup() {
    return {
      getValidationState
    };
  }
};
</script> 
