import { render, staticRenderFns } from "./MultiChoiceMatchingQuestion.vue?vue&type=template&id=07d89c74&scoped=true&"
import script from "./MultiChoiceMatchingQuestion.vue?vue&type=script&lang=js&"
export * from "./MultiChoiceMatchingQuestion.vue?vue&type=script&lang=js&"
import style0 from "./MultiChoiceMatchingQuestion.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./MultiChoiceMatchingQuestion.vue?vue&type=style&index=1&id=07d89c74&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07d89c74",
  null
  
)

export default component.exports