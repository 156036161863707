<template>
  <section>
    <b-sidebar
      id="add-questions-sidebar"
      v-model="openSideBar"
      :title="sideBarTitle"
      bg-variant="white"
      right
      shadow
      backdrop
      lazy
      aria-controls="add-questions-sidebar"
      :aria-expanded="openSideBar"
      :width="sideBarWidth"
    >
      <validation-observer
        ref="observer"
        v-slot="{ pristine, invalid, handleSubmit, errors }"
      >
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <aom-languages
            :dirty-tabs="isTabDataDirty(errors)"
            is-add-mode
          >
            <question-common
              slot="header"
              :is-matching-question="isMatchingQuestion"
              :user-roles="userRoles"
              :form-type="formType"
              :form-id="formId"
            />
            
            <template slot-scope="props">
              <b-row>
                <component
                  :is="isComponent"
                  :index="props.index"
                  :locale-id="props.localeId"
                  :is-matching-question="isMatchingQuestion"
                  :question-order="questionOrder"
                  @updateSidebarWidth="updateSidebarWidth"
                  @decreaseSidebarWidth="decreaseSidebarWidth"
                  @isMatchQuestion="addMatchQuestion"
                  @updateMatchingQuestionType="updateMatchingQuestionType"
                />
                <component
                  :is="isMatchingComponent"
                  v-if="isMatchingQuestion"
                  :index="props.index"
                  :locale-id="props.localeId"
                  :is-matching-question="isMatchingQuestion"
                  :question-order="questionOrder"
                  :matching-question-type="matchingQuestionType"
                />
              </b-row>
            </template>
          </aom-languages>
          <b-card>
            <b-row class="mt-5">
              <b-col
                sm="6"
                md="4"
                class="mb-1"
              >
                <b-button
                  block
                  type="reset"
                  variant="outline-dark"
                  @click="clearForm"
                >
                  Cancel
                </b-button>
              </b-col>
              <b-col
                sm="6"
                md="4"
                class="mb-1"
              >
                <b-button
                  block
                  type="submit"
                  variant="primary"
                >
                  <b-spinner
                    v-if="isUpdating" 
                    small
                  />
                  <span
                    v-if="isUpdating"
                  > Updating...</span>
                  <span v-else>Submit</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BCard,
  BModal,
  BSidebar,
  BButton,
  BSpinner,
  BForm
} from "bootstrap-vue";

import AddSingleTextBoxQuestionForm from "./text/AddSingleTextBoxQuestionForm.vue";
import AddMultipleChoiceQuestionForm from "./multi-choice/AddMultipleChoiceQuestionForm.vue";
import AddRatingScalesQuestionForm from './rating-scale/AddRatingScalesQuestionForm.vue';
import MultiChoiceMatchingQuestion from "./matching/MultiChoiceMatchingQuestion.vue";
import Ripple from 'vue-ripple-directive';
import AomLanguages from "@aom-core/AomLanguages";
import AddQuestionButton from './AddQuestionButton.vue';
import QuestionCommon from './QuestionCommon.vue';
import { ValidationObserver } from "vee-validate";
import { mapGetters,mapMutations } from 'vuex';
import { clearMatchLines } from './matching/matching-lines';

const MIN_SIDEBAR_WIDTH = '60%';
const MAX_SIDEBAR_WIDTH = '80%';

export default {
  components: {
    BCard,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BModal,
    BSidebar,
    AddSingleTextBoxQuestionForm,
    AddMultipleChoiceQuestionForm,
    AddRatingScalesQuestionForm,
    MultiChoiceMatchingQuestion,
    AomLanguages,
    AddQuestionButton,
    QuestionCommon,
    BButton,
    BSpinner,
    BForm,
    ValidationObserver
  },
  directives: {
    Ripple,
  },
  props: {
    questionType: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    open: {
      type: Boolean,
      default: false
    },
    formId: {
      type: [Number, String],
      default: 0
    },
    userRoles: {
      type: Array,
      default: () => []
    },
    formType: {
      type: Object,
      default: () => {}
    },
    isMatchingQuestion: {
      type: Boolean
    },
    isUpdating: {
      type: Boolean
    },
    questionOrder: {
      type: Number, 
      default: 0
    }
  },
  data(vm) {
    return {
      sideBarWidth: MIN_SIDEBAR_WIDTH,
      isComponent: vm.questionType,
      sideBarTitle: vm.title,
      openSideBar: vm.open,
      isMatchingQuestionLocal: vm.isMatchingQuestion,
      isMatchingComponent: MultiChoiceMatchingQuestion,
      matchingQuestionType: undefined
    };
  },
  computed: {
    ...mapGetters('questions', ['questionCommon', 'multiChoiceMatchingQuestion']),
  },
  watch: {
    questionType() {
      this.isComponent = this.questionType;
    },
    open() {
      this.openSideBar = this.open;
    },
    title() {
      this.sideBarTitle = this.title;
    },
    isMatchingQuestion() {
      this.isMatchingQuestionLocal = this.isMatchingQuestion;
      this.sideBarWidth = MAX_SIDEBAR_WIDTH;
    },
    openSideBar(n) {
      if(!n) {
        this.clearForm();
        this.$emit('toggleSideBar', n);
      }
    }
  },
  methods: {
    closeSideBar () {
      this.openSideBar = false;
      this.isComponent = undefined;
      this.clearForm();
    },
    updateSidebarWidth() {
      this.sideBarWidth = MAX_SIDEBAR_WIDTH;
    },
    decreaseSidebarWidth() {
      this.sideBarWidth = MIN_SIDEBAR_WIDTH;
    },
    clearForm() {
      this.openSideBar = false;
      this.matchingQuestionType = undefined;
      this.CLEAR_QUESTION();
      clearMatchLines();
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    onSubmit() {
      if(!this.isMatchingQuestionLocal) {
        this.$emit('submit',{formId: this.formId, question: this.questionCommon});
      } else {
        this.$emit('submitMatching', {formId: this.formId, weight: this.questionCommon.weight, questions:[this.questionCommon, this.multiChoiceMatchingQuestion]});
      }
    },
    addMatchQuestion(value) {
      if(value) {
        this.updateSidebarWidth();
      } else {
        this.decreaseSidebarWidth();
      }
      this.isMatchingQuestion = value;
      this.isMatchingComponent = MultiChoiceMatchingQuestion;
      this.sideBarTitle = 'Add New Question - Matching';
    },
    updateMatchingQuestionType (type) {
      const {value} = type;
      return this.matchingQuestionType = value;
    }, 
    isTabDataDirty (errors) {
      let tabErrorIndex = [];
      for (const [key, error] of Object.entries(errors)) {
        const regex = /\d+/g;
        if(error.length > 0) {
          const tabIndex = key.match(regex);
          if(Array.isArray(tabIndex)) {
            tabErrorIndex.push(tabIndex[0]);
          }
        }
      }
      return tabErrorIndex;
    },
    ...mapMutations('questions',
      ['ADD_QUESTION', 'CLEAR_QUESTION']
    ),
  }
};
</script>

<style lang="scss">
  $sidebar-header-text-color: #808080;
  .b-sidebar > .b-sidebar-header{
    flex-direction: row-reverse;
    background: #f3f3f3;
    color: $sidebar-header-text-color;

    #add-questions-sidebar___title__ {
      font-size: .8em;
      flex: 2;
    }
  }
</style>