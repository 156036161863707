<template>
  <vue-good-table
    class="mt-3"
    mode="remote"
    :is-loading="isLoading"
    :columns="columns"
    :rows="rows"
    :rtl="directionIsRTL"
    :search-options="{
      enabled: true,
      externalQuery: searchTerm,
    }"
    :pagination-options="{
      enabled: true,
      perPage: perPage,
    }"
    style-class="vgt-table striped"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <span v-if="props.column.field === 'title'">
        <span v-if="props.row.translations[0]">{{ props.row.translations[0].title }}</span> 
      </span>
      <span v-if="props.column.field === 'question_text'">
        <span v-if="props.row.translations[0]">{{ props.row.translations[0].question_text }}</span> 
      </span>
      <span v-if="props.column.field === 'userRole'">
        <span v-if="props.row.target_roles">{{ props.row.target_roles }}</span> 
      </span>
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
      <!-- Column: Help Link -->
      <!-- Column: Actions -->
      <div
        v-if="props.column.field === 'actions'"
        cols="12"
        md="2"
        class="vgt-center-align"
      >
        <b-button
          v-if="props.row.type_id === questionTypes.MATCHING"
          variant="outline-none"
          @click="editMatchingQuestion({id:props.row.question_matched_pair_id, qType:questionTypes.MATCHING})"
        >
          <feather-icon
            icon="EditIcon"
            size="20"
          />
        </b-button>
        <b-button
          v-else
          variant="outline-none"
          @click="editQuestion(props.row.id)"
        >
          <feather-icon
            icon="EditIcon"
            size="20"
          />
        </b-button>
        <b-button
          v-if="props.row.type_id === questionTypes.MATCHING"
          variant="outline-none"
          @click="deleteMatchingQuestion(props.row.question_matched_pair_id)"
        >
          <feather-icon
            icon="TrashIcon"
            size="20"
          />
        </b-button>
        <b-button
          v-else
          variant="outline-none"
          @click="deleteQuestion(props.row.id)"
        >
          <feather-icon
            icon="TrashIcon"
            size="20"
          />
        </b-button>
      </div>
    </template>

    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <table-pagination
        :per-page="perPage"
        :total="total"
        @perPageChanged="
          (value) => props.perPageChanged({ currentPerPage: value })
        "
        @pageChanged="(value) => props.pageChanged({ currentPage: value })"
      />
    </template>
  </vue-good-table>
</template>

<script>
import {
  BButton,
  VBToggle,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";

import {
  GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
  GOODTABLE_LOCALISED_DATE_FORMAT,
  GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
  PRIMARY_DATE_FORMAT,
  localDateStringOrDateRangeStringToUtcRange
} from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";

import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import questionsService from '@/services/questionsService';
import { questionTypesDisplay, participantRolesDisplay, questionTypes } from '@models';
import useAomTableConfig from "@aom-core/useAomTableConfig.js";

export default {
  components: {
    VueGoodTable,
    BButton,
    TablePagination,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    reload: {
      type: Boolean
    }
  },
  data() {
    return {
      isLoading: false,
      element: {},
      dir: false,
      questionTypes,
      columns: [
        {
          label: "Title",
          field: "title",
          filterOptions: {
            enabled: true,
            placeholder: "Search Title",
          },
          width: "15em",
        },
        {
          label: "Question",
          field: "question_text",
          filterOptions: {
            enabled: true,
            placeholder: "Search Question Text",
          },
          width: "15em",
        },
        {
          label: "Question Type",
          field: "type",
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: Object.values(questionTypesDisplay)
          },
          formatFn: this.formatFnType,
          sortFn: this.sortFn,
          width: "15em",
        },
        {
          label: "User Role",
          field: "target_roles",
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: participantRolesDisplay
          },
          formatFn: this.formatFnUserRole,
          width: "8em",
        },
        {
          label: "Last Modified",
          field: "updated_at",
          filterOptions: { 
            enabled: true,
            placeholder: "Filter Date",
            filterFn: this.dateFilter,
          },
          tdClass: "text-center",
          width: "10em",
          type: "date",
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT,
        },
        {
          field: "actions",
          width: "10em",
          tdClass: "float-left",
          sortable: false
        },
      ],
      columnFilters: [],
      sort: [],
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
  },
  watch: {
    reload() {
      this.loadItems();
    }
  },
  mounted() {
    const elements = document.getElementsByName("vgt-updated_at");
    elements.forEach(function (input) {
      flatPickr(input, {
        enableTime: false,
        dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT, 
        allowInput: true,
        altInput: true,
        altFormat: PRIMARY_DATE_FORMAT,
        mode: "range",
      });
    });
  },
  created() {
    this.loadItems();
  },
  methods: {
    dateFilter(data, filterString) {
      return (data = Date.parse(data) >= Date.parse(filterString));
    },

    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of ["title", "question_text", "target_roles", "updated_at", "type"]) {
        if (params.columnFilters[col]) {
          if(col === 'target_roles'){
            let roleIds = [];
            roleIds.push(params.columnFilters.target_roles);
            columnFilters.push({
              field: col,
              value: roleIds
            });
          } else if (col === "updated_at") {
            const dateRange = localDateStringOrDateRangeStringToUtcRange(params.columnFilters[col]);
            if (dateRange) {
              columnFilters.push({
                field: col,
                value: dateRange,
              });
            }
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },
    formatFnType: function(value) {
      return value.name;
    },
    formatFnUserRole (value) {
      let roles = [];
      if(Array.isArray(value)) {
        roles = [...roles, value.map(r => r.name)];
      }
      return roles.toString();
    },
    async loadItems() {
      try {
        this.isLoading = true;
        const response = await questionsService.getList({
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
        this.total = response.data.total;
        this.rows = response.data.items;
      } catch (e) {
        const { status, data } = e.response;
        //server may respond with vaidation errors
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
        } else {
          this.$log.error(e);
        }
        return;
      } finally {
        this.isLoading = false;
      }
      return;
    },
    editQuestion(id) {
      this.$nextTick(()=> {
        this.$emit('edit', id);
      });
    },
    editMatchingQuestion(id) {
      this.$nextTick(()=> {
        this.$emit('editMatching', id);
      });
    },
    deleteQuestion(id) {
      this.$nextTick(()=> {
        this.$emit('delete', id);
      });
    },
    deleteMatchingQuestion(id) {
      this.$nextTick(()=> {
        this.$emit('deleteMatching', id);
      });
    }
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();
    return {
      total,
      perPage,
      page
    };
  }
};
</script>

<style lang="scss" scoped>
.float-left{
  float: left;
}

.badge {
  font-weight: 400 !important;
}
table.vgt-table td {
  vertical-align: middle;
}
</style>

<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
</style>
