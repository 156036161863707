<!-- eslint-disable vue/valid-v-model -->
<template>
  <section class="col-sm-12">
    <text-box-question-component
      :index="index"
      :title="questionTitle"
      :question-text="questionText"
      @updateTitle="updateQuestionTitle"
      @updateQuestionText="updateQuestionText"
    />
    <b-row
      v-for="indexRow in (questionCommon.statements.length + 1)" 
      :key="indexRow" 
      class="mt-1 flex-nowrap"
    >
      <b-col 
        v-for="indexCol in (questionCommon.choices.length + 2)"
        :key="indexCol" 
        :class="getRowClass(indexRow,indexCol)"
      >
        <div
          v-if="indexRow === 1 && indexCol === (questionCommon.choices.length + 2)"
          class="minimal-width"
        >
          <b-button
            v-if="isAddOptionsEnabled"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="mt-n1"
            variant="flat-primary"
            size="lg"
            @click="addNewOption"
          >
            +
          </b-button>
        </div>

        <div
          v-if="indexRow === 1 && indexCol > 1 && indexCol < (questionCommon.choices.length + 2) "
          class="rating-option"
        >
          <validation-provider
            v-slot="{ errors }"
            :ref="`Option ${indexCol - 1}`"
            :vid="`option-${index}-${indexCol - 1}`"
            :rules="{ required: isRequired }"
            :name="`Option ${indexCol - 1}`"
          >
            <b-input-group 
              :label-for="'option' + (indexCol-1) "
            >
              <b-form-textarea
                v-if="questionCommon.choices[indexCol - 2].translations.filter(t => t.locale_id === localeId).shift()"
                v-model="questionCommon.choices[indexCol - 2].translations.filter(t => t.locale_id === localeId).shift().choice_text"
                type="text"
                class="min-statement-width"
                :state="errors.length > 0 ? false : null"
                :placeholder="'Option ' + (indexCol-1) "
                style="overflow:hidden"
                size="sm"
                no-resize
                @keyup="adjustHeight($event)"
                @focus="adjustHeight($event)"
              />
              <div class="input-group-append">
                <b-button
                  variant="outline-none"
                  class="no-padding-button"
                  @click="deleteOption(indexCol - 2)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="15"
                  />
                </b-button>
              </div>
            </b-input-group>
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </div>
        <div
          v-else-if="indexRow > 1 && indexCol === 1"
        >
          <validation-provider
            v-slot="{ errors }"
            :ref="`Statement ${indexRow - 1}`"
            :vid="`statement-${index}-${indexRow - 1}`"
            :rules="{ required: isRequired }"
            :name="`Statement ${indexRow - 1}`"
          >
            <b-input-group 
              :label-for="'statement' + (indexRow-1) "
            >
              <b-form-textarea
                v-if="questionCommon.statements[indexRow - 2].translations.filter(t => t.locale_id === localeId).shift()"
                v-model="questionCommon.statements[indexRow - 2].translations.filter(t => t.locale_id === localeId).shift().question_text"
                class="min-statement-width" 
                type="text"
                :state="errors.length > 0 ? false : null"
                :placeholder="`Statement ${indexRow - 1}`"
                style="overflow:hidden"
                size="sm"
                no-resize
                @keyup="adjustHeight($event)"
                @focus="adjustHeight($event)"
              />
              <div class="input-group-append">
                <b-button
                  variant="outline-none"
                  class="no-padding-button"
                  @click="deleteStatement(indexRow - 2)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="15"
                  />
                </b-button>
              </div>
            </b-input-group>
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </div>
        <div v-else-if="indexRow > 1 && indexCol > 1 && indexCol < (questionCommon.choices.length + 2)">
          <b-form-radio
            disabled
            name="option-radio" 
            class="ml-3"
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col md="10">
        <b-button
          v-if="isAddOptionsEnabled"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-primary"
          size="lg"
          @click="addNewStatement"
        >
          + Add Statement
        </b-button>
      </b-col>
    </b-row> 
  </section>
</template>

<script>
import {
  BRow, BCol, BButton, 
  VBModal, BFormRadio, BInputGroup, BFormTextarea
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider } from "vee-validate";
import { getValidationState } from "@/libs/utils";
import { QuestionChoiceClass, QuestionChoiceTranslationClass, QuestionsTranslationsClass, QuestionStatementClass } from '@models/questionClass';
import { questionTypes } from '@models/questionTypes';
import { makeErrorToast } from "@/libs/utils";
import { mapMutations, mapGetters } from 'vuex';
import  TextBoxQuestionComponent  from "@/views/components/questions/TitleQuestionComponent.vue";
import { locales as localeModel } from '@models/locales';

const DEFAULT_OPTIONS = 2;
const MAX_DEFAULT_OPTIONS = 15;

export default {
  components: {
    BRow,
    BCol,
    BFormRadio,
    BButton,
    ValidationProvider,
    TextBoxQuestionComponent,
    BInputGroup,
    BFormTextarea
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: { 
    index: {
      type: Number,
      default: 0
    },
    localeId: {
      type: Number,
      default: 0
    },
    locales: {
      type: Array,
      default:() => []
    },
    questionOrder: {
      type: Number,
      default: 0
    }
  },
  inject: ['currentTab'],
  data() {
    return {
      dir: "ltr",
      title: undefined,
      selectedFormType: undefined,
      selectedUserRoles: [],
      isUpdating: false,
    };
  },
  computed: {
    ...mapGetters('questions', ['questionCommon']),
    isRequired() {
      let isTabDataDirty = false;
      for (const [, text] of Object.entries(this.$refs)) {
        if(text.value) {
          isTabDataDirty = true;
        }
      }
      return this.index === this.currentTab.value || isTabDataDirty;
    },
    questionTitle() {
      return this.questionCommon?.translations.filter(t => t.locale_id === this.localeId).shift()?.title || '';

    },
    questionText() {
      return this.questionCommon?.translations.filter(t => t.locale_id === this.localeId).shift()?.question_text || '';
    },
    isAddOptionsEnabled() {
      return this.localeId === localeModel.EN;
    },
    hasChoicesTranslation () {
      const findIndex = this.questionCommon.choices.filter(c => c.translations.find(t => t.locale_id === this.localeId));
      return findIndex.length > 0;
    },
    hasChoicesTranslationRequired () {
      const findIndex = this.questionCommon.choices.filter(c => c.translations.find(t => t.locale_id === this.localeId && t.choice_text));
      return findIndex.length > 0;
    },
  },
  watch: {
    'currentTab.value' (n) {
      if(n === this.index ) {
        this.renderChoicesForLocale();
      }
      if(this.hasChoicesTranslation && !this.hasChoicesTranslationRequired && n !== this.index) {
        this.removePaddedChoices();
        this.removePaddedStatements();
      }
    }
  },
  created() {
    if(this.questionCommon.choices.length === 0) {
      this.createInitalOptions();
    }
  },
  methods: {
    renderChoicesForLocale () {
      // merge exsiting to new translations CHOICES
      const findIndex = this.questionCommon.choices.filter(c => c.translations.find(t => t.locale_id === this.localeId));
      if(findIndex.length === 0) {
        const choiceTranslation = this.questionCommon.choices.map(c => ({
          ...c,
          translations: [...c.translations, {...new QuestionChoiceTranslationClass({locale_id: this.localeId})}]
        }));
        this.UPDATE_QUESTION_CHOICE_TRANS(choiceTranslation);
      } else {
        const choiceTranslation = this.questionCommon.choices.map(c => ({
          ...c
        }));
        this.UPDATE_QUESTION_CHOICE_TRANS(choiceTranslation);
      }
      // merge exsiting to new translations STATEMENTS
      const findStateMentIndex = this.questionCommon.statements.filter(c => c.translations.find(t => t.locale_id === this.localeId));
      if(findStateMentIndex.length === 0) {
        const statementTranslation = this.questionCommon.statements.map(s => ({
          ...s,
          translations: [...s.translations, {...new QuestionsTranslationsClass({locale_id: this.localeId})}]
        }));
        this.UPDATE_QUESTION_STATEMENT_TRANS(statementTranslation);
      } else {
        const statementTranslation = this.questionCommon.statements.map(s => ({
          ...s
        }));
        this.UPDATE_QUESTION_STATEMENT_TRANS(statementTranslation);
      }
    },
    updateQuestionTitle (title) {
      const trans = {title, locale_id: this.localeId };
      this.UPDATE_QUESTION_TRANS(trans);
    },
    updateQuestionText (text) {
      const trans = {question_text: text, locale_id: this.localeId };
      this.UPDATE_QUESTION_TRANS(trans);
    },
    addNewOption(){ 
      if(this.questionCommon.choices.length >= MAX_DEFAULT_OPTIONS)
      {
        this.$toast(makeErrorToast("Maxium options reached"));
        return;
      }
      const newChoiceOrder = this.questionCommon.choices.length;
      const newOption = [
        ...this.questionCommon.choices,
        {
          ...new QuestionChoiceClass({
            translations: [
              {...new QuestionChoiceTranslationClass({
                locale_id: this.localeId
              }),
              }
            ],
            choice_order: newChoiceOrder
          })
        }
      ];
      // Function PADS the options to be equal across all tabs
      const localeIdToAdd = this.locales.find(l => l !== this.localeId);
      const maxLength = Math.max(...newOption.map(el => el.translations.length));
      const padArray = newOption.map(c => {
        if(c.translations.length !== maxLength) {
          c.translations.push({...new QuestionChoiceTranslationClass({locale_id: localeIdToAdd})});
          return c;
        }  
        return c; 
      });
      this.UPDATE_QUESTION_CHOICE_TRANS(padArray);

    },
    deleteOption (indexToRemove) {
      if(this.questionCommon.choices.length === 1) {
        return;
      }
      const keepOptions = this.questionCommon.choices.filter((c, index) => index !== indexToRemove);
      this.UPDATE_QUESTION({
        choices: keepOptions
      });
      this.updateChoiceOrder();    
    },
    updateChoiceOrder() {
      const choiceOrder = this.questionCommon.choices.map((c, index) => ({ ...c, choice_order: index}));
      this.UPDATE_QUESTION({
        choices: choiceOrder
      });
    },
    deleteStatement (indexToRemove) {
      if(this.questionCommon.statements.length === 1) {
        return;
      }
      const keepOptions = this.questionCommon.statements.filter((c, index) => index !== indexToRemove);
      this.UPDATE_QUESTION({
        statements: keepOptions
      }); 
    },
    addNewStatement () {
      const newStatement = [
        ...this.questionCommon.statements,
        {
          ...new QuestionStatementClass({
            translations: [{...new QuestionsTranslationsClass({
              locale_id: this.localeId
            })}]
          })
        }
      ];
      // Function PADS the statemenst to be equal across all tabs
      const localeIdToAdd = this.locales.find(l => l !== this.localeId);
      const maxLength = Math.max(...newStatement.map(el => el.translations.length));
      const padArray = newStatement.map(s => {
        if(s.translations.length !== maxLength) {
          s.translations.push({...new QuestionChoiceTranslationClass({locale_id: localeIdToAdd})});
          return s;
        }  
        return s; 
      });
      this.UPDATE_QUESTION_STATEMENT_TRANS(padArray); 
    },
    getRowClass(row,col) {
      if((row > 1 || row === 1) && col === 1){
        return 'col-sm-3';
      }
      return 'col-sm-3';
    },
    adjustHeight(el){
      const e = el.target;
      e.style.height = (e.scrollHeight > e.clientHeight) ? (e.scrollHeight) + "px" : "auto";
    },
    createInitalOptions() {
      const choices = [];
      const statements = [];
      for (let i = 0; i < DEFAULT_OPTIONS; i++) {
        // Add choices
        choices.push({
          ...new QuestionChoiceClass({
            translations: [
              {...new QuestionChoiceTranslationClass({
                locale_id: this.localeId
              })
              }
            ],
            choice_order: i
          })
        });
        // Add statements
        statements.push({
          ...new QuestionStatementClass({
            translations: [{...new QuestionsTranslationsClass({
              locale_id: this.localeId
            })}]
          })
        });
      };
      this.UPDATE_QUESTION({
        answer_character_limit: 250,
        type_id: questionTypes.SCALE,
        choices: choices,
        statements: statements,
        question_order: this.questionOrder
      });
    },
    removePaddedChoices () {
      if(this.questionCommon.choices.length > 0) {
        // merge exsiting to new translations CHOICES
        const transChoices = this.questionCommon.choices.filter(c => c.translations.find(t => t.locale_id === this.localeId));
        if(transChoices.length > 0) {
          const choiceTranslation = this.questionCommon.choices.map(c => ({
            ...c,
            translations: c.translations.filter(t => t.locale_id !== this.localeId)
          }));
          this.UPDATE_QUESTION_CHOICE_TRANS(choiceTranslation);
        }
      }
    },
    removePaddedStatements() {
      if(this.questionCommon.statements.length > 0) {
        const transStatements = this.questionCommon.statements.filter(c => c.translations.find(t => t.locale_id === this.localeId));
        if(transStatements.length > 0) {
          const statementsTranslation = this.questionCommon.statements.map(s => ({
            ...s,
            translations: s.translations.filter(t => t.locale_id !== this.localeId)
          }));
          this.UPDATE_QUESTION_STATEMENT_TRANS(statementsTranslation); 
        }
      }
    },
    ...mapMutations('questions',
      [
        'ADD_QUESTION',
        'UPDATE_QUESTION',
        'CLEAR_QUESTION', 
        'UPDATE_QUESTION_TRANS', 
        'UPDATE_QUESTION_CHOICE_TRANS',
        'UPDATE_QUESTION_STATEMENT_TRANS'
      ]
    )
  },
  setup() {
    return {
      getValidationState
    };
  },
};
</script> 

<style lang="scss" scoped>
  .question-radio{
    margin: 0 auto;
  }
  .font-bold {
      font-weight: bold;
  }
  .float-right {
    float: right;
  }
  .no-padding-button {
    padding:0 2px;
  }
  .min-statement-width {
    height: 2.142rem;
  }
</style>