<template>
  <section>
    <!-- Table Top -->
    <b-sidebar
      id="edit-questions-sidebar"
      v-model="openSideBar"
      :title="sideBarTitle"
      bg-variant="white"
      right
      shadow
      backdrop
      lazy
      aria-controls="edit-question-sidebar"
      :aria-expanded="openSideBar"
      :width="sideBarWidth"
    >
      <validation-observer
        ref="observer"
        v-slot="{ handleSubmit, errors }"
      >
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <div v-if="isLoading">
            <question-skeleton-loader />
          </div>
          <aom-languages
            v-else
            :dirty-tabs="isTabDataDirty(errors)"
            :is-add-mode="false"
          >
            <question-common
              slot="header"
              :is-matching-question="isMatchingQuestion"
              :form-type="formType"
              :form-id="formId"
            />
              
            <template slot-scope="props">
              <b-row>
                <component
                  :is="isComponent"
                  :index="props.index"
                  :locale-id="props.localeId"
                  :locales="props.locales"
                  :is-loading="isLoading"
                  :is-edit="true"
                  :is-matching-question="isMatchingQuestion"
                  @updateSidebarWidth="updateSidebarWidth"
                  @decreaseSidebarWidth="decreaseSidebarWidth"
                  @isMatchQuestion="addMatchQuestion"
                  @updateMatchingQuestionType="updateMatchingQuestionType"
                />
                <component
                  :is="isMatchingComponent"
                  v-if="isMatchingQuestion"
                  :index="props.index"
                  :locale-id="props.localeId"
                  :locales="props.locales"
                  :is-matching-question="isMatchingQuestion"
                  :matching-question-type="matchingQuestionType"
                  :is-edit="true"
                />
              </b-row>
            </template>
          </aom-languages>
          <b-card>
            <b-row class="mt-50">
              <b-col
                sm="6"
                md="4"
                class="mb-1"
              >
                <b-button
                  block
                  type="reset"
                  variant="outline-dark"
                  @click="clearForm"
                >
                  Cancel
                </b-button>
              </b-col>
              <b-col
                sm="6"
                md="4"
                class="mb-1"
              >
                <b-button
                  block
                  type="submit"
                  variant="primary"
                >
                  <b-spinner
                    v-if="isUpdating" 
                    small
                  />
                  <span
                    v-if="isUpdating"
                  > Updating...</span>
                  <span v-else>Submit</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-sidebar>
  </section>
</template>
  
<script>
import {
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BCard,
  BModal,
  BSidebar,
  BButton,
  BSpinner,
  BForm
} from "bootstrap-vue";
  
import AddSingleTextBoxQuestionForm from "./text/AddSingleTextBoxQuestionForm.vue";
import AddMultipleChoiceQuestionForm from "./multi-choice/AddMultipleChoiceQuestionForm.vue";
import AddRatingScalesQuestionForm from './rating-scale/AddRatingScalesQuestionForm.vue';
import MultiChoiceMatchingQuestion from "./matching/MultiChoiceMatchingQuestion.vue";
import Ripple from 'vue-ripple-directive';
import { questionTypes } from '@models/questionTypes';
import AomLanguages from "@aom-core/AomLanguages";
import AddQuestionButton from './AddQuestionButton.vue';
import QuestionCommon from './QuestionCommon.vue';
import { ValidationObserver } from "vee-validate";
import questionsService from '@/services/questionsService';
import { mapGetters,mapMutations } from 'vuex';
import { makeErrorToast } from "@/libs/utils";
import QuestionSkeletonLoader from "./QuestionSkeletonLoader.vue";
import { userRoles } from '@models/userRoles';
import { clearMatchLines } from './matching/matching-lines';

const MIN_SIDEBAR_WIDTH = '60%';
const MAX_SIDEBAR_WIDTH = '80%';
  
export default {
  name: 'EditQuestionSideBar',
  components: {
    BCard,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BModal,
    BSidebar,
    AddSingleTextBoxQuestionForm,
    AddMultipleChoiceQuestionForm,
    AddRatingScalesQuestionForm,
    MultiChoiceMatchingQuestion,
    AomLanguages,
    AddQuestionButton,
    QuestionCommon,
    BButton,
    BSpinner,
    BForm,
    ValidationObserver,
    QuestionSkeletonLoader
  },
  directives: {
    Ripple,
  },
  props: {
    questionType: {
      type: [String, Number],
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    open: {
      type: Boolean,
      default: false
    },
    questionId: {
      type: Number,
      default: undefined
    },
    isUpdating: {
      type: Boolean
    },
    formId: {
      type: [Number, String],
      default: 0
    },
    formType: {
      type: Object,
      default: () => {}
    },
  },
  data(vm) {
    return {
      sideBarWidth: MIN_SIDEBAR_WIDTH,
      isComponent: vm.questionType,
      sideBarTitle: vm.title,
      openSideBar: vm.open,
      isMatchingQuestion: false,
      isMatchingComponent: undefined,
      matchingQuestionType: undefined,
      isLoading: false
    };
  },
  computed: {
    ...mapGetters('questions', ['questionCommon', 'multiChoiceMatchingQuestion']),
  },
  watch: {
    questionType: {
      handler() {
        this.isComponent = this.questionType;
      }, 
      immediate: true
    },
    open() {
      this.openSideBar = this.open;
    },
    title() {
      this.sideBarTitle = this.title;
    },
    openSideBar(n) {
      if(!n) {
        this.clearForm();
        this.$emit('toggleSideBar', n);
      }
    },
    questionId: {
      handler() {
        if(this.questionType === questionTypes.MATCHING) {
          this.editQuestionMatching();
        } else {
          this.editQuestion();
        }
      }, 
      immediate: true
    },
    isMatchingQuestion(n) {
      if(n) {
        this.sideBarWidth = MAX_SIDEBAR_WIDTH;
      }
    }
  },
  methods: {
    fetchQuestionService(programId, formId, questionId) {
      if (this.$route.meta.isProgramRoute) {
        return questionsService.readProgramFormQuestion(programId, formId, questionId);
      }
      return questionsService.getQuestionById(questionId);
    },
    async editQuestion() {
      if(this.questionId) {
        try {
          this.isComponent = undefined;
          this.isLoading = true;
          const programId = this.$route.params.id;
          const response = await this.fetchQuestionService(programId, this.formId, this.questionId);
          const { data } = response;
          this.openSideBar = true;
          this.sideBarTitle = `Edit Question - ${data.translations[0].question_text}`;
          this.$nextTick(() => {
            this.isComponent = this.getQuestionComponent(data.type_id);
          });
          this.CLEAR_QUESTION();
          this.UPDATE_QUESTION(data);
        } catch (e) {
          console.log(e);
          this.$toast(makeErrorToast('Unable to Fetch Question'));
        } finally {
          this.isLoading = false;
        }
      }
    },
    getQuestionComponent(typeId) {
      if (typeId === questionTypes.TEXT) {
        return "AddSingleTextBoxQuestionForm";
      }
      if (typeId === questionTypes.CHOICE) {
        return "AddMultipleChoiceQuestionForm";
      }
      if (typeId === questionTypes.SCALE) {
        return "AddRatingScalesQuestionForm";
      }
      if (typeId === questionTypes.MATCHING) {
        return "AddMultipleChoiceQuestionForm";
      }
    },
    updateSidebarWidth() {
      this.sideBarWidth = MAX_SIDEBAR_WIDTH;
    },
    decreaseSidebarWidth() {
      this.sideBarWidth = MIN_SIDEBAR_WIDTH;
    },
    clearForm() {
      this.openSideBar = false;
      this.isComponent = undefined;
      this.sideBarTitle = undefined;
      this.isUpdating = false;
      this.isMatchingQuestion = false;
      this.isMatchingComponent = undefined;
      this.matchingQuestionType = undefined;
      this.isLoading = false;
      clearMatchLines();
      this.CLEAR_QUESTION();
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    onSubmit() {
      if(!this.isMatchingQuestion) {
        this.$emit('update',{formId: this.formId, question: this.questionCommon});
      } else {
        this.$emit('updateMatching', {weight: this.questionCommon.weight, questions:[this.questionCommon, this.multiChoiceMatchingQuestion]});
      }
    },
    addMatchQuestion(value) {
      if(value) {
        this.updateSidebarWidth();
      } else {
        this.decreaseSidebarWidth();
      }
      this.isMatchingQuestion = value;
      this.isMatchingComponent = 'MultiChoiceMatchingQuestion';
      this.sideBarTitle = 'Add New Question - Matching';
    },
    updateMatchingQuestionType (type) {
      const {value} = type;
      return this.matchingQuestionType = value;
    }, 
    isTabDataDirty (errors) {
      let tabErrorIndex = [];
      for (const [key, error] of Object.entries(errors)) {
        const regex = /\d+/g;
        if(error.length > 0) {
          const tabIndex = key.match(regex);
          if(Array.isArray(tabIndex)) {
            tabErrorIndex.push(tabIndex[0]);
          }
        }
      }
      return tabErrorIndex;
    },
    fetchMatchingQuestionService(programId, applicationSetId, questionId) {
      if (this.$route.meta.isProgramRoute) {
        return questionsService.readProgramMatchingQuestion(programId, applicationSetId, questionId);
      }
      return questionsService.getMatchingQuestionById(questionId);
    },
    async editQuestionMatching() {
      if(this.questionId) {
        try {
          this.isComponent = undefined;
          this.isLoading = true;
          const programId = this.$route.params.id;
          const applicationSetId = this.$route.params.application;
          const response = await this.fetchMatchingQuestionService(programId, applicationSetId, this.questionId);
          const { data } = response;
          const {questions, weight} = data;
          this.isMatchingQuestion = true;
          this.matchingQuestionType = questionTypes.MATCHING;
          this.openSideBar = true;
          this.sideBarTitle = `Edit Matching Question`;
          this.isComponent = 'AddMultipleChoiceQuestionForm',
          this.isMatchingComponent = 'MultiChoiceMatchingQuestion';
          this.CLEAR_QUESTION();
          this.updateMentorQuestion(questions, weight);
          this.updateMenteeQuestion(questions, weight);

        } catch (e) {
          console.log(e);
          this.$toast(makeErrorToast('Unable to Fetch Matching Question'));
        } finally {
          this.isLoading = false;
        }
      }
    },
    updateMentorQuestion(questions, weight) {
      // Mentor
      const mentorQuestion = Object.assign({weight}, ...questions.filter(q => q.target_roles.every(r => r.id === userRoles.MENTOR)));
      this.UPDATE_QUESTION(mentorQuestion);
    },
    updateMenteeQuestion(questions, weight) {
      // Mentee
      const menteeQuestion = Object.assign({weight}, ...questions.filter(q => q.target_roles.every(r => r.id === userRoles.MENTEE)));
      this.UPDATE_MATCHING_QUESTION(menteeQuestion);    
    },
    ...mapMutations('questions',
      ['ADD_QUESTION', 'CLEAR_QUESTION', 'UPDATE_QUESTION', 'UPDATE_MATCHING_QUESTION', 'SET_MATCHED_CHOICES']
    ),
  }
};
</script>
  
  <style lang="scss">
    $sidebar-header-text-color: #808080;
    .b-sidebar > .b-sidebar-header{
      flex-direction: row-reverse;
      background: #f3f3f3;
      color: $sidebar-header-text-color;
  
      #edit-questions-sidebar___title__ {
        font-size: .8em;
        flex: 2;
      }
    }
    .card {
      box-shadow: none;
    }
  </style>