<template>
  <b-row>
    <b-col md="12">
      <validation-provider
        v-slot="validationContext"
        :ref="`prefilled-answer-${index}`"
        :vid="`prefilled-answer-${index}`"
        :rules="{ required: isRequired, min: 10 }"
        name="Prefilled answer"
      >
        <b-form-group label-for="prefilled-answer">
          <b-form-textarea
            v-model="prefilledAnswerLocal"
            placeholder="Prefilled answer"
            maxlength="500"
            rows="3"
            :state="
              getValidationState(
                validationContext
              )
            "
          />
          <b-form-invalid-feedback
            :state="
              getValidationState(
                validationContext
              )
            "
          >
            {{
              validationContext.errors[0]
            }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BFormGroup, 
  BFormTextarea, BFormInvalidFeedback
} from 'bootstrap-vue';
    
import { ValidationProvider } from "vee-validate";
import { getValidationState } from "@/libs/utils";

export default {
  name: 'SingleTextBoxAnswer',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea, 
    BFormInvalidFeedback,
    ValidationProvider
  },
  props: {
    prefilledAnswer: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    }
  },
  inject: ['localeId', 'currentTab'],
  data() {
    return {
      prefilledAnswerLocal: this.prefilledAnswer
    };
  },
  computed: {
    isRequired() {
      return this.index === this.currentTab.value;
    }
  },
  watch: {
    prefilledAnswer(n) {
      this.prefilledAnswerLocal = n;
    },
    prefilledAnswerLocal () {
      this.$emit('update', this.prefilledAnswerLocal);
    }
  },
  setup() {
    return {
      getValidationState,
    };
  }
};
</script>
