<template>
  <div
    class="col-sm-6"
  >
    <h5 v-if="isMatchingQuestion">
      {{ roleAlias(userRoles.MENTEE, defaultProgram) }}
    </h5>
    <text-box-question-component
      :index="index"
      :title="questionTitle"
      :is-matching-question="isMatchingQuestion"
      :question-text="questionText"
      @updateTitle="updateQuestionTitle"
      @updateQuestionText="updateQuestionText"
    />
    <b-row>
      <b-col
        sm="12"
        class="d-flex justify-content-end mt-2"
      >
        <b-button
          variant="primary"
          @click="deleteAllMappings"
        >
          <feather-icon
            icon="TrashIcon"
            size="15"
          />
          Remove all default mappings
        </b-button>
      </b-col>
    </b-row>
    <div
      v-if="isVerticalDirection"
      id="link-wrapper"
      :class="{'edit-margin': !isEdit}"
    >
      <b-row>
        <b-col
          v-if="hasChoicesTranslation"
          md="12"
        >
          <draggable
            v-model="multiChoiceMatchingQuestion.choices"
            class="list-group list-group-flush cursor-move question-builder-wrapper"
            tag="ul"
            @change="updateListSortOrder"
          >      
            <div 
              v-for="(choice, i) in multiChoiceMatchingQuestion.choices" 
              :id="selectedOptions[i] === true ? `multi-choice-wrapper-${i}-${index}`: ''" 
              :key="i"
              ref="multiChoiceWrapper"
              class="mt-1 multi-choice-wrapper"
              :class="{'active': selectedOptions[i] === true}"
              @mouseover="choice.isMouseHovered = true"
              @mouseleave="choice.isMouseHovered = false"
            >
              <b-row>
                <b-col sm="10">
                  <b-row>
                    <b-col md="2">
                      <b-form-checkbox 
                        v-model="selectedOptions[i]"
                        name="question-options"
                        class="mt-1"
                        :disabled="index !== 0"
                        @change="isLinkedTarget(i, choice, $event)"
                      />
                    </b-col>
                    <b-col md="10">
                      <validation-provider
                        v-if="!choice.is_other"
                        v-slot="validationContext"
                        :ref="`form.${index}-question.${index}.choice.${i}.translations.${index}.choice_text`"
                        :rules="{ required: isRequired }"
                        :vid="`form.${index}-question.${index}.choice.${i}.translations.${index}.choice_text`"
                        :name="`Choice Option ${i + 1}`"
                      >
                        <b-form-input
                          v-model="multiChoiceMatchingQuestion.choices[i].translations.filter(t => t.locale_id === localeId).shift().choice_text"
                          type="text"
                          :placeholder="`Option ${i + 1}`"
                        />
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                  </b-row> 
                </b-col>
                <b-col sm="2">
                  <b-button
                    v-if="index === 0"
                    variant="outline-none"
                    @click="deleteOption(i)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      size="15"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </draggable>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <div class="question-radio">
        <b-form-radio 
          :name="'question-options_' + indexRow"
          :value=" indexRow+'_'+indexCol"
          class="mt-1"
        />
      </div>
    </div>
    <b-row
      v-if="index === 0"
      class="mt-2"
    >
      <b-col md="5">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-primary"
          size="lg"
          @click="addNewOption()"
        >
          + Add option
        </b-button>
      </b-col>
    </b-row>
    <b-row
      v-if="isSelectMultiAnswersEnabled"
      class="mt-2"
    >
      <b-col md="12">
        <div class="d-flex">
          <b-form-group label-for="multiple-answers">
            <b-form-checkbox
              id="multipleMenteeAnswers"
              v-model="multipleMenteeAnswers"
              checked="true"
              name="multiple-answers"
              switch
              inline
            />
          </b-form-group>
          <div class="d-flex">
            <h4 class="ml-1">
              Multiple Answers
            </h4>

            <validation-provider
              v-if="multipleMenteeAnswers"
              v-slot="validationContext"
              ref="QuantityOptions"
              :rules="{ required: isRequired }"
              name="Quantity Options"
            >
              <b-form-group 
                label-for="max-quantity-options"
              >
                <v-select 
                  v-model="multiChoiceMatchingQuestion.choice_limit"
                  :options="multipleAnswersOptions"
                  class="ml-2 mt-n1"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
  
<script>
import {
  BRow,
  BCol,
  BButton,
  VBModal, 
  BFormInvalidFeedback, 
  BFormCheckbox,
  BFormInput,
  BFormRadio,
  BFormGroup
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import  TextBoxQuestionComponent  from "@/views/components/questions/TitleQuestionComponent.vue";
import { getValidationState } from "@/libs/utils";
import { ValidationProvider } from "vee-validate";
import { QuestionChoiceClass, QuestionChoiceTranslationClass} from '@models/questionClass';
import draggable from 'vuedraggable';
import { mapMutations, mapGetters } from 'vuex';
import { questionTypes } from '@models/questionTypes';
import { matchingQTypes } from './model/matchingQuestionType.js';
import questionService from '@/services/questionsService';
import { updateMatchLines, startMatchLink, trackMatchingOptions, clearMatchLines, setLocalesForQuestionChoice} from '../matching/matching-lines';
import _delay from "lodash/delay";
import { formTypes } from '@models/formTypes';
import { RolesClass } from '@models/userRolesClass';
import { userRoles } from '@models/userRoles';
import _isEqual from 'lodash/isEqual';
import { locales as localeModel } from '@models/locales';
import vSelect from "vue-select";
import { roleAlias } from "@/@aom-core/utils/utils";

export default {
  name: 'MultiChoiceMatchingQuestion',
  components: {
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BFormInvalidFeedback,
    BFormInput,
    BFormCheckbox,
    BFormRadio,
    BFormGroup,
    TextBoxQuestionComponent,
    ValidationProvider,
    draggable,
    vSelect
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props:{
    index: {
      type: Number,
      default: 0
    },
    isMatchingQuestion: {
      type: Boolean
    },
    matchingQuestionType: {
      type: [Number, String],
      default: matchingQTypes.NEW
    },
    localeId: {
      type: Number,
      default: 0
    },
    isEdit: {
      type: Boolean
    },
    locales: {
      type: Array,
      default:() => []
    },
    questionOrder: {
      type: Number,
      default: 0
    }
  },
  inject: ['currentTab'],
  data() {
    return {
      matchQuestion: false,
      maxQuantityOptions: 1,
      selectedFormType: undefined,
      selectedUserRoles: [],
      isUpdating: false,
      multiChoiceOptions:[],
      initialOptions: 2,
      multipleMenteeAnswers: false,
      isVerticalDirection: true,
      indexRow: 1,
      indexCol: 1,
      isMatchQuestionLocal: false,
      isLoading: false,
      selectedOptions: [],
      isNotNewQuestion: false
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgram']),
    hasChoicesTranslation () {
      const findIndex = this.multiChoiceMatchingQuestion.choices.filter(c => c.translations.find(t => t.locale_id === this.localeId));
      return findIndex.length > 0;
    },
    hasChoicesTranslationRequired () {
      const findIndex = this.multiChoiceMatchingQuestion.choices.filter(c => c.translations.find(t => t.locale_id === this.localeId && t.choice_text));
      return findIndex.length > 0;
    },
    hasOtherAsOption() {
      return this.multiChoiceMatchingQuestion.choices.find(c => c.is_other === true);
    },
    isMatchQuestionView() {
      return (this.isMatchQuestionLocal || this.isMatchingQuestion) ? 'col-sm-6' : 'col-sm-12';
    },
    isMatchTypeSelected() {
      return this.matchingQuestionType && this.multiChoiceMatchingQuestion && !this.isLoading;
    },
    questionTitle() {
      return this.multiChoiceMatchingQuestion?.translations.filter(t => t.locale_id === this.localeId).shift()?.title || '';
    },
    questionText() {
      return  this.multiChoiceMatchingQuestion?.translations.filter(t => t.locale_id === this.localeId).shift()?.question_text || '';
    },
    isRequired() {
      let isTabDataDirty = false;
      for (const [, text] of Object.entries(this.$refs)) {
        if(text.value) {
          isTabDataDirty = true;
        }
      }
      return this.index === this.currentTab.value || isTabDataDirty;
    },
    isAddOptionsEnabled() {
      return this.localeId === localeModel.EN;
    },
    isSelectMultiAnswersEnabled() {
      return this.isAddOptionsEnabled;
    },
    multipleAnswersOptions () {
      return Array.from({length: this.multiChoiceMatchingQuestion.choices.length - 1}, (_, i) => i + 2);
    },
    ...mapGetters('questions', ['multiChoiceMatchingQuestion', 'questionCommon', 'parentSourceIndex', 'parentSourceId'])
  },
  watch: {
    isMatchQuestionLocal(n) {
      this.$emit('isMatchQuestion', n);
    },
    matchingQuestionType: {
      handler() {
        if(this.matchingQuestionType) {
          this.createQuestionModel();
        }
      },
      immediate: true
    },
    parentSourceIndex: {
      handler(n) {
        if(n !== undefined) {
          this.isChecked(n);
        }
      }
    },
    'currentTab.value' (n) {
      if(n === this.index ) {
        this.renderChoicesForLocale();
        this.$nextTick(() => {
          trackMatchingOptions(startMatchLink);
        });
      }
      if(this.hasChoicesTranslation && !this.hasChoicesTranslationRequired && n !== this.index) {
        this.removePaddedChoices();
      }
    },
    'questionCommon.choices': {
      handler(n, o) {
        // update matching lines if user selects a new mapping or removes one
        if(_isEqual(n,o)) {
          trackMatchingOptions(startMatchLink);
        }
      },
      deep: true,
      immediate: true
    },
    multipleMenteeAnswers(n) {
      if(!n) {
        this.multiChoiceMatchingQuestion.choice_limit = 1;
      }
    }
  },
  created() {
    this.updateListSortOrder();
    if(this.multiChoiceMatchingQuestion.choices.length > 0) {
      this.selectedOptions = this.multiChoiceMatchingQuestion.choices.map(() => false);
    }
    if( this.multiChoiceMatchingQuestion.choice_limit > 1) {
      this.multipleMenteeAnswers = true;
    }
  },
  mounted() {
    this.setScrollTargets();
  },
  methods: {
    setScrollTargets() {
      let scrollTarget = undefined;
      if(this.isEdit) {
        scrollTarget = document.querySelector('#edit-questions-sidebar .b-sidebar-body');
      } else {
        scrollTarget = document.querySelector('.b-sidebar-body');
      }
      if(scrollTarget) {
        const callback = () => _delay(() => { updateMatchLines(); }, 150);
        scrollTarget.addEventListener('scroll', callback);
      }
    },
    updateQuestionTitle (title) {
      const trans = {title, locale_id: this.localeId };
      this.UPDATE_MATCHING_QUESTION_TRANS(trans);
    },
    updateQuestionText (text) {
      const trans = {question_text: text, locale_id: this.localeId };
      this.UPDATE_MATCHING_QUESTION_TRANS(trans);
    },
    addNewOption() {
      const newChoiceOrder = this.multiChoiceMatchingQuestion.choices.length;
      const newOption = [
        ...this.multiChoiceMatchingQuestion.choices,
        {
          ...new QuestionChoiceClass({
            translations: [{...new QuestionChoiceTranslationClass({
              locale_id: this.localeId
            })}],
            choice_order: newChoiceOrder
          }),
          isMouseHovered: false
        }
      ];
      // Function PADS the options to be equal across all tabs
      const padArray = newOption.map(choice => setLocalesForQuestionChoice(choice, this.locales));

      this.UPDATE_MATCHING_QUESTION({
        choices: padArray
      });
    },
    updateListSortOrder () {
      const newList = [...this.multiChoiceMatchingQuestion.choices].map((item, index) => {
        item.choice_order = index;
        return item;
      });
      this.multiChoiceMatchingQuestion.choices = newList;
    },  
    deleteOption (indexToRemove) {
      if(this.multiChoiceMatchingQuestion.choices.length === 1) {
        return;
      }
      this.$bvModal
        .msgBoxConfirm("Removing this option will reset and remove all the other mappings for this matching question and you will need to re-map the all the options.", {
          title: 'Remove Option',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Ok, I will re-map',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            clearMatchLines();
            this.selectedOptions = this.selectedOptions.map(() => false);
            const itemToRemove = this.multiChoiceMatchingQuestion.choices[indexToRemove];
            if (itemToRemove) {
              this.REMOVE_MANY_MATCHED_CHOICES({type: 'target', choice: itemToRemove});
            }
            const keepOptions = this.multiChoiceMatchingQuestion.choices.filter((c, index) => index !== indexToRemove);
            const newOptions = keepOptions.map((o,i) => ({...o, choice_order: i}));
            this.UPDATE_MATCHING_QUESTION({
              choices: newOptions
            });
          }
        });
    },
    deleteAllMappings() {
      this.$bvModal
        .msgBoxConfirm("This will remove all the mappings for this matching question and you will need to re-map the all the options.", {
          title: 'Remove Mapping',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Ok, I will re-map',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            clearMatchLines();
            this.selectedOptions = this.selectedOptions.map(() => false);
            this.REMOVE_MANY_MATCHED_CHOICES({type: 'target', choice: 0});
           
          }
        });
    },
    addOtherOption () {
      const newChoice = [
        ...this.multiChoiceMatchingQuestion.choices,
        {
          ...new QuestionChoiceClass({
            is_other: true,
            translations: [{...new QuestionChoiceTranslationClass({
              locale_id: this.localeId
            })}]
          }),
          isMouseHovered: false
        }
      ];
      this.UPDATE_MATCHING_QUESTION({
        choices: newChoice
      });
    },
    async fetchQuestionById() {
      try {
        this.isLoading = true;
        const response = await questionService.getQuestionById(this.matchingQuestionType);
        const { data } = response;
        if(data) {
          this.isNotNewQuestion = true;
          const targetRoles = new RolesClass({ id: userRoles.MENTEE, name: 'Mentee' });
          this.UPDATE_MATCHING_QUESTION(
            {
              ...data,
              type_id: questionTypes.MATCH,
              target_form_type_id: formTypes.APPLICATION,
              target_roles: [targetRoles]
            });
        }
      } catch(e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    createQuestionModel() {
      if (this.matchingQuestionType === matchingQTypes.NEW) {
        const choices = [];
        for (let i = 0; i < this.initialOptions; i++) {
          choices.push({
            ...new QuestionChoiceClass({
              translations: [{...new QuestionChoiceTranslationClass({
                locale_id: this.localeId,
              })}],
              choice_order: i
            }),
            isMouseHovered: false
          });
        }
        // Is set as the Mentee side of a matched pair ...
        const targetRoles = new RolesClass({ id: userRoles.MENTEE, name: 'Mentee' });
        this.UPDATE_MATCHING_QUESTION({
          type_id: questionTypes.MATCH,
          target_form_type_id: formTypes.APPLICATION,
          target_roles: [targetRoles],
          translations: [],
          choices: choices,
          question_order: this.questionOrder
        });
        return;
      }
      if (
        this.matchingQuestionType !== matchingQTypes.NEW && !this.isEdit) {
        return this.fetchQuestionById();
      }
    },
    isLinkedTarget(i, choice, $event) {
      if($event) {
        this.ADD_MATCHED_CHOICES({index: i, type: `target`, choice: choice});
      } else {
        this.REMOVE_MATCHED_CHOICES({index: i, type: `target`, choice: choice});
      }
    },
    isChecked() {
      // when no ids 
      if(this.questionCommon.choices.length > 0 && this.questionCommon.choices[this.parentSourceIndex]) {
        this.selectedOptions = this.multiChoiceMatchingQuestion.choices.map(() => false);
        this.questionCommon.choices.forEach(c => { 
          c.matched_choices.forEach(m => {
            if(m.parent_index === this.parentSourceIndex) {
              this.selectedOptions[m.choice_order] = true;
            }
            if(m?.pivot?.choice_id === this.parentSourceId) {
              // find matching indexs
              const indexs = [];
              this.multiChoiceMatchingQuestion.choices.forEach((c, cIndex) => {
                if(c.matched_choices && c.matched_choices.length) {
                  c.matched_choices.forEach(m => {
                    if(m.id === this.parentSourceId) {
                      indexs.push(cIndex);
                    }
                  });
                }
              });
              if(indexs.length > 0) {
                indexs.forEach(m => {
                  this.selectedOptions[m] = true;
                });
              }
                
            }
          });
        });
      }
    },
    padMissingTranslations() {
      if(this.multiChoiceMatchingQuestion.choices.length > 0) {
        // merge exsiting to new translations CHOICES
        const findIndex = this.multiChoiceMatchingQuestion.choices.filter(c => c.translations.find(t => t.locale_id === this.localeId));
        if(findIndex.length === 0) {
          const choiceTranslation = this.multiChoiceMatchingQuestion.choices.map(c => ({
            ...c,
            translations: [...c.translations, {...new QuestionChoiceTranslationClass({locale_id: this.localeId})}]
          }));
          this.UPDATE_MATCHING_QUESTION_CHOICE_TRANS(choiceTranslation);
        }

      } 
    },
    renderChoicesForLocale () {
      this.padMissingTranslations();
    },
    removePaddedChoices () {
      if(this.multiChoiceMatchingQuestion.choices.length > 0) {
        // merge exsiting to new translations CHOICES
        const transChoices = this.multiChoiceMatchingQuestion.choices.filter(c => c.translations.find(t => t.locale_id === this.localeId));
        if(transChoices.length > 0) {
          const choiceTranslation = this.multiChoiceMatchingQuestion.choices.map(c => ({
            ...c,
            translations: c.translations.filter(t => t.locale_id !== this.localeId)
          }));
          this.UPDATE_MATCHING_QUESTION_CHOICE_TRANS(choiceTranslation);
        }
      } 
    },
    ...mapMutations('questions',
      [
        'REMOVE_MANY_MATCHED_CHOICES', 'UPDATE_MATCHING_QUESTION','UPDATE_MATCHING_QUESTION_TRANS', 
        'ADD_MATCHED_CHOICES', 'REMOVE_MATCHED_CHOICES', 'UPDATE_MATCHING_QUESTION_CHOICE_TRANS'
      ]
    )
  },
  setup() {
    return {
      getValidationState,
      roleAlias,
      userRoles
    };
  },
};
</script>
  
  <style lang="scss">
    @import '@/assets/scss/vue/libs/tour.scss';
  </style>
  <style scoped lang="scss">

    .fl-r{
      float: right;
    }
    .card .card-title {
      color: white;
    }
    #link-wrapper {
      margin-top: 19px;
    }
    #link-wrapper.edit-margin {
      margin-top: 20px;
      margin-bottom: 35px;
    }
    .active {
      padding: 10px 20px;
      border-radius: 5px;
      border: 1px solid rgba(205, 222, 135, 1);;
      background-color: rgba(205, 222, 135, 1);
    }
  </style>