var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{ref:("title-" + _vm.validationProviderId),attrs:{"vid":("title-" + _vm.validationProviderId),"rules":{ required: _vm.isRequired },"name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Title","label-for":"title"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Title","state":_vm.getValidationState(
                validationContext
              )},model:{value:(_vm.selectedTitle),callback:function ($$v) {_vm.selectedTitle=$$v},expression:"selectedTitle"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                validationContext
              )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{ref:("question-" + _vm.validationProviderId),attrs:{"vid":("question-" + _vm.validationProviderId),"rules":{ required: _vm.isRequired },"name":"Question"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Question","label-for":"question"}},[_c('b-form-textarea',{attrs:{"placeholder":"Question","maxlength":"500","rows":"3","state":_vm.getValidationState(
                validationContext
              )},model:{value:(_vm.selectedQuestionText),callback:function ($$v) {_vm.selectedQuestionText=$$v},expression:"selectedQuestionText"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                validationContext
              )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }