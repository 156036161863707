import store from '@/store';
import LeaderLine from 'leader-line-vue';
import {QuestionChoiceTranslationClass} from '@models/questionClass';
let track = new Map();
let mappedLinks = new Map();
let index = 0;

export let startMatchLink = undefined;

function drawLineStartEnd(start, end) {
  const line = new LeaderLine.setLine(
    start,
    end,
    {
      color: '#cdde87', 
      size: 2, 
      path: 'grid',
      startPlug: 'behind',
      endPlug: 'behind',
      startSocket: 'right',
      endPlug: 'square'
    }
  );
  track.set(line, {'start': start});
}

const findProgramLanguages = () => {
  return store.getters['programs/defaultProgram'].languages || [];
};

export function drawMatchingLine(start, options = {}) {
  const end = document.querySelectorAll('[id^=multi-choice-wrapper]');
  let newStart = Array.from(document.querySelectorAll(`[id=${start}]`));
  findProgramLanguages().forEach((e,i) => {
    const parts = start.split("-");
    const newTab = document.querySelectorAll(`[id=${parts[0]}-${parts[1]}-${parts[2]}-${i}]`);
    if(i === 0) {
      return;
    }
    if(newTab.length > 0 && i > 0) {
      newStart.push(newTab[0]);
    }
  });
    
  newStart.forEach(s =>  {
    const id = s.id;
    const parts = id.split("-");
    const tabIndexStart= parts[parts.length - 1];
    end.forEach(e => {
      const id = e.id;
      const parts = id.split("-");
      const tabIndexEnd= parts[parts.length - 1];
      if(tabIndexStart === tabIndexEnd) {
        drawLineStartEnd(s, e);
      }
    });
  });
}
export function trackMatchingOptions(element) {
  const MATCH_LINK_REGEX = /match-link-\d+-\d+/g;
  if(element) {
    const matchStart = element.match(MATCH_LINK_REGEX);
    startMatchLink = matchStart[0];
    if(Array.isArray(matchStart)) {
      if(track.size > 0) {
        track.forEach((l, i) => (
          i.remove(),
          track.delete(i)
        )
        );
      }
      drawMatchingLine(matchStart[0]);
      index++;
    }
  }
}

export function updateMatchLines() {
  track.forEach((l, i) => (
    i.position()
  )
  );
}
export function clearMatchLines() {
  track.forEach((l, i) => (
    i.remove()
  ));
  track.clear();
}


export const setLocalesForQuestionChoice = (choice, locales, is_other = false) => {
  locales.forEach(localeId => {
    let locale = choice.translations.find(translation => {
      return translation.locale_id === localeId;
    });
    if (!locale) {
      choice.translations.push({...new QuestionChoiceTranslationClass({locale_id: localeId}), is_other});
    }
  });
  return choice; 
};
