<template>
  <div
    :class="isMatchingQuestion ? 'col-sm-6' : 'col-sm-12'"
  >
    <h5 v-if="isMatchingQuestion">
      {{ roleAlias(userRoles.MENTOR, defaultProgram) }}
    </h5>
    <text-box-question-component
      :index="index"
      :title="questionTitle"
      :question-text="questionText"
      @updateTitle="updateQuestionTitle"
      @updateQuestionText="updateQuestionText"
    />

    <b-row v-if="isMatchingQuestion">
      <b-col
        sm="12"
        class="mt-1 mb-50"
      >
        <b-form-group
          label-for="question-weight"
          label="Select Question Weight"
          class="mb-0"
        >
          <v-select
            id="question-weight"
            v-model="questionCommon.weight"
            label="name"
            placeholder="Select the Question Weight"
            :options="questionWeight"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div>
      <b-row>
        <b-col
          v-if="hasChoicesTranslation"
          sm="12"
        >
          <draggable
            v-model="questionCommon.choices"
            class="list-group list-group-flush cursor-move question-builder-wrapper"
            tag="ul"
            @change="updateListSortOrder"
          >      
            <div 
              v-for="(choice, i) in questionCommon.choices" 
              :key="choice.choice_order" 
              ref="multiChoiceWrapper"
              @mouseover="choice.isMouseHovered = true"
              @mouseleave="choice.isMouseHovered = false"
            >
              <b-row
                v-if="!choice.is_other"
                class="mt-1 multi-choice-wrapper"
              >
                <b-col md="8">
                  <b-row>
                    <b-col md="2">
                      <b-form-radio 
                        v-if="!multipleAnswers"
                        name="question-options"
                        class="mt-1"
                        disabled
                      />
                      <b-form-checkbox 
                        v-else
                        name="question-options"
                        class="mt-1"
                        disabled
                      />
                    </b-col>
                    <b-col md="9">
                      <validation-provider
                        v-if="!choice.is_other"
                        v-slot="validationContext"
                        :ref="`question.${index}.choice.${i}.translations.${index}.choice_text`"
                        :vid="`question.${index}.choice.${i}.translations.${index}.choice_text`"
                        :rules="{ required: isRequired }"
                        :name="`Option`"
                      >
                        <b-form-input
                          v-model="questionCommon.choices[i].translations.filter(t => t.locale_id === localeId).shift().choice_text"
                          type="text"
                          :placeholder="`Option ${choice.choice_order + 1}`"
                        />
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                  </b-row> 
                </b-col>
                <b-col md="1">
                  <b-button
                    v-if="index === 0"
                    variant="outline-none"
                    @click="deleteOption(i)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      size="15"
                    />
                  </b-button>
                </b-col>
                <b-col
                  v-if="isMatchingQuestion"
                  sm="2"
                >
                  <b-button
                    :id="`match-link-${i}-${index}`"
                    class="`match-link-button`"
                    :class="{active: i === parentSourceIndex}"
                    :variant="isOptionLinked(i, choice)"
                    @click="linkOption(i, choice, $event, `match-link-${i}-${index}`)"
                  >
                    <feather-icon
                      icon="LinkIcon"
                      size="15"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </draggable>
          <div 
            v-for="(choice, i) in questionCommon.choices" 
            :key="i" 
            ref="multiChoiceWrapper"
            class="mt-1 multi-choice-wrapper"
            @mouseover="choice.isMouseHovered = true"
            @mouseleave="choice.isMouseHovered = false"
          >
            <b-row v-if="choice.is_other">
              <b-col md="8">
                <b-row>
                  <b-col sm="2">
                    <span class="text-muted">Other</span>
                  </b-col>
                  <b-col sm="9">
                    <validation-provider
                      v-slot="validationContext"
                      :ref="`question.${index}.choice.${i}.translations.${index}.choice_text`"
                      :rules="{ required: isRequired }"
                      :vid="`question.${index}.choice.${i}.translations.${index}.choice_text`"
                      :name="`Choice Other`"
                    >
                      <b-form-input
                        v-model="questionCommon.choices[i].translations.filter(t => t.locale_id === localeId).shift().choice_text"
                        type="text"
                        placeholder="Other"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="1">
                <b-button
                  v-if="index === 0"
                  variant="outline-none"
                  @click="deleteOption(i)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="15"
                  />
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-row class="mt-2">
      <b-col md="5">
        <b-button
          v-if="isAddOptionsEnabled"
          variant="flat-primary"
          size="lg"
          @click="addNewOption()"
        >
          + Add option
        </b-button>
      </b-col>
      <b-col
        v-if="!isMatchingQuestion"
        md="5"
      >
        <b-button
          v-if="!hasOtherAsOption && isAddOptionsEnabled"
          variant="flat-primary"
          size="lg"
          @click="addOtherOption()"
        >
          + Add "Other" option
        </b-button>
      </b-col>
    </b-row>
    <b-row
      v-if="isSelectMultiAnswersEnabled"
      class="mt-2"
    >
      <b-col md="12">
        <div class="d-flex">
          <b-form-group label-for="multiple-answers">
            <b-form-checkbox
              id="multipleAnswers"
              v-model="multipleAnswers"
              checked="true"
              name="multiple-answers"
              switch
              inline
            />
          </b-form-group>
          <div class="d-flex">
            <h4 class="ml-1">
              Multiple Answers
            </h4>

            <validation-provider
              v-if="multipleAnswers"
              v-slot="validationContext"
              ref="QuantityOptions"
              :rules="{ required: isRequired }"
              name="Quantity Options"
            >
              <b-form-group 
                label-for="max-quantity-options"
              >
                <v-select 
                  v-model="questionCommon.choice_limit"
                  :options="multipleAnswersOptions"
                  class="ml-2 mt-n1"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, 
  BButton,
  VBModal, 
  BFormInvalidFeedback, 
  BFormCheckbox,
  BFormInput,
  BFormRadio
} from 'bootstrap-vue';
import vSelect from "vue-select";
import  TextBoxQuestionComponent  from "@/views/components/questions/TitleQuestionComponent.vue";
import { getValidationState } from "@/libs/utils";
import { ValidationProvider } from "vee-validate";
import { QuestionChoiceClass, QuestionChoiceTranslationClass } from '@models/questionClass';
import draggable from 'vuedraggable';
import { mapMutations, mapGetters } from 'vuex';
import { questionTypes } from '@models/questionTypes';
import { trackMatchingOptions, clearMatchLines, setLocalesForQuestionChoice} from '../matching/matching-lines';
import { locales as localeModel } from '@models/locales';
import _cloneDeep from 'lodash/cloneDeep';
import { formTypes } from '@models/formTypes';
import { RolesClass } from '@models/userRolesClass';
import { userRoles } from '@models/userRoles';
import _isEqual from 'lodash/isEqual';
import { roleAlias } from "@/@aom-core/utils/utils";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    vSelect,
    BFormCheckbox,
    BFormInvalidFeedback,
    BFormInput,
    BFormCheckbox,
    BFormRadio,
    TextBoxQuestionComponent,
    ValidationProvider,
    draggable
  },
  directives: {
    'b-modal': VBModal
  },
  props:{
    index: {
      type: Number,
      default: 0
    },
    isMatchingQuestion: {
      type: Boolean
    },
    isLoading: {
      type: Boolean,
    },
    isEdit: {
      type: Boolean
    },
    localeId: {
      type: Number,
      default: 0
    },
    locales: {
      type: Array,
      default:() => []
    },
    questionOrder: {
      type: Number,
      default: 0
    }
  },
  inject: ['currentTab'],
  data() {
    return {
      matchQuestion: false,
      maxQuantityOptions: 1,
      selectedFormType: undefined,
      selectedUserRoles: [],
      isUpdating: false,
      multiChoiceOptions:[],
      initialOptions: 2,
      multipleAnswers: false,
      indexRow: 1,
      indexCol: 1,
      questionWeight: Array.from({length: 10}, (_, i) => i + 1)
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgram']),
    hasChoicesTranslation () {
      const findIndex = this.questionCommon.choices.filter(c => c.translations.find(t => t.locale_id === this.localeId));
      return findIndex.length > 0;
    },
    hasChoicesTranslationRequired () {
      const findIndex = this.questionCommon.choices.filter(c => c.translations.find(t => t.locale_id === this.localeId && t.choice_text));
      return findIndex.length > 0;
    },
    hasOtherAsOption() {
      return Boolean(this.questionCommon.choices.find(c => c.is_other === true));
    },
    isRequired() {
      let isTabDataDirty = false;
      for (const [, text] of Object.entries(this.$refs)) {
        if(text.value) {
          isTabDataDirty = true;
        }
      }
      return this.index === this.currentTab.value || isTabDataDirty;
    },
    questionTitle() {
      return this.questionCommon?.translations.filter(t => t.locale_id === this.localeId).shift()?.title || '';
    },
    questionText() {
      return  this.questionCommon?.translations.filter(t => t.locale_id === this.localeId).shift()?.question_text || '';
    },
    isAddOptionsEnabled() {
      return this.localeId === localeModel.EN;
    },
    isSelectMultiAnswersEnabled() {
      return this.isAddOptionsEnabled;
    },
    originalModel() {
      return Object.assign({},  _cloneDeep(this.questionCommon));
    },
    questionTypeId () {
      return this.isMatchingQuestion ? questionTypes.MATCHING : questionTypes.CHOICE;
    },
    multipleAnswersOptions () {
      return Array.from({length: this.questionCommon.choices.length - 1}, (_, i) => i + 2);
    },
    isMultiAnswers() {
      return this.questionCommon.choice_limit > 1;
    },
    ...mapGetters('questions', ['questionCommon', 'parentSourceIndex'])
  },
  watch: {
    'currentTab.value' (n) {
      if(n === this.index ) {
        this.renderChoicesForLocale();
      }
      if(this.hasChoicesTranslation && !this.hasChoicesTranslationRequired && n !== this.index) {
        this.removePaddedChoices();
      }
    },
    multipleAnswers(n) {
      if(!n) {
        this.questionCommon.choice_limit = 1;
      }
    },
    'questionCommon.choices': {
      handler(n, o) {
        if(_isEqual(n, o)) {
          return;
        }
        if(n) {
          if(Array.isArray(n)) {
            this.updateChoiceOther();
          }
        }
      },
      deep: true
    }
  },
  created() {
    this.updateListSortOrder();
    if(this.questionCommon.choices.length === 0) {
      this.createInitalOptions();
    }
    if(this.questionCommon.choice_limit > 1) {
      this.multipleAnswers = true;
    }    
  },
  methods: {
    isOptionLinked(index, option) {
      return option.matched_choices.length > 0 ? 'primary' : 'bg-light';
    },
    renderChoicesForLocale() {
      const findIndex = this.questionCommon.choices.filter(c => c.translations.find(t => t.locale_id === this.localeId));
      if(findIndex.length === 0) {
        const choiceTranslation = this.questionCommon.choices.map(c => ({
          ...c,
          translations: [...c.translations, {...new QuestionChoiceTranslationClass({locale_id: this.localeId})}]
        }));
        this.UPDATE_QUESTION_CHOICE_TRANS(choiceTranslation);
      }
    },
    updateQuestionTitle (title) {
      const trans = {title, locale_id: this.localeId };
      this.UPDATE_QUESTION_TRANS(trans);
    },
    updateQuestionText (text) {
      const trans = {question_text: text, locale_id: this.localeId };
      this.UPDATE_QUESTION_TRANS(trans);
    },
    addNewOption(){
      const newChoiceOrder = this.questionCommon.choices.filter(choice => !choice.is_other).length;
      const newOption = [
        ...this.questionCommon.choices,
        {
          ...new QuestionChoiceClass({
            translations: [{...new QuestionChoiceTranslationClass({
              locale_id: this.localeId
            })}],
            choice_order: newChoiceOrder
          }),
          isMouseHovered: false
        }
      ];
      // Function PADS the options to be equal across all tabs
      const padArray = newOption.map(choice => setLocalesForQuestionChoice(choice, this.locales));
      this.UPDATE_QUESTION_CHOICE_TRANS(padArray);
    },
    updateListSortOrder () {
      const newList = [...this.questionCommon.choices].map((item, index) => {
        item.choice_order = index;
        return item;
      });
      this.questionCommon.choices = newList;
    },  
    deleteOption (indexToRemove) {
      if(this.questionCommon.choices.length === 1) {
        return;
      }
      if(this.isMatchingQuestion) {
        this.deleteMatchingOptions(indexToRemove);
      } else {
        const keepOptions = this.questionCommon.choices.filter((c, index) => index !== indexToRemove);
        const sortedArray = [
          ...keepOptions.filter(c => !c.is_other),
          ...keepOptions.filter(c => c.is_other)
        ];
        const newOptions = sortedArray.map((o,i) => ({...o, choice_order: i}));
        this.UPDATE_QUESTION({
          choices: newOptions
        }); 
      }
      
      
    },
    deleteMatchingOptions(indexToRemove) {
      this.$bvModal
        .msgBoxConfirm("Removing this option will reset and remove all the other mappings for this matching question and you will need to re-map the all the options.", {
          title: 'Remove Option',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Ok, I will re-map',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            clearMatchLines();
            const itemToRemove = this.questionCommon.choices[indexToRemove];
            if (itemToRemove) {
              this.REMOVE_MANY_MATCHED_CHOICES({type: 'source', choice: itemToRemove});
            }
            const keepOptions = this.questionCommon.choices.filter((c, index) => index !== indexToRemove);
            const newOptions = keepOptions.map((o,i) => ({...o, choice_order: i}));
            this.UPDATE_QUESTION({
              choices: newOptions
            }); 
          }
        });
    },
    addOtherOption () {
      const newChoiceOrder = this.questionCommon.choices.length;
      const newOther = [
        ...this.questionCommon.choices,
        {
          ...new QuestionChoiceClass({
            translations: [{...new QuestionChoiceTranslationClass({
              locale_id: this.localeId
            })}],
            is_other : true,
            choice_order: newChoiceOrder
          }),
          isMouseHovered: false
        }
      ];
      // Function PADS the options to be equal across all tabs'
      const padArray = newOther.map(choice => setLocalesForQuestionChoice(choice, this.locales, true));
      this.UPDATE_QUESTION_CHOICE_TRANS(padArray);
    },
    updateMatchingQuestionType (value) {
      this.$emit('updateMatchingQuestionType', value);
    },
    linkOption (i, choice, $event, id) {
      this.ADD_MATCHED_CHOICES({index:i, type: 'source', choice:choice});
      this.$nextTick(() => {
        trackMatchingOptions(id);
      });
    },
    createInitalOptions() {
      const choices = [];
      for (let i = 0; i < this.initialOptions; i++) {
        choices.push({
          ...new QuestionChoiceClass({
            translations: [
              { ...new QuestionChoiceTranslationClass({locale_id: this.localeId})}
            ],
            choice_order: i
          }),
          isMouseHovered: false
        });
      }   
      if(this.isMatchingQuestion) {
        // Is the mentor side of and application and set as MENTOR
        const targetRoles = new RolesClass({ id: userRoles.MENTOR, name:'Mentor' });
        this.UPDATE_QUESTION({
          type_id: this.questionTypeId,
          target_form_type_id: formTypes.APPLICATION,
          target_roles: [targetRoles],
          choices: choices,
          question_order: this.questionOrder
        });
      } else {
        this.UPDATE_QUESTION({
          type_id: this.questionTypeId,
          choices: choices,
          question_order: this.questionOrder
        });
      }
    },
    isActiveMatch (index) {
      return this.parentSourceIndex = index;
    },
    updateChoiceOther() {
      this.questionCommon.choices = this.questionCommon.choices.map(c => {
        if(c.is_other) {
          return {
            ...c,
            choice_order: this.questionCommon.choices.length
          };
        }
        return c;
      });
    },
    removePaddedChoices () {
      if(this.questionCommon.choices.length > 0) {
        // merge exsiting to new translations CHOICES
        const transChoices = this.questionCommon.choices.filter(c => c.translations.find(t => t.locale_id === this.localeId));
        if(transChoices.length > 0) {
          const choiceTranslation = this.questionCommon.choices.map(c => ({
            ...c,
            translations: c.translations.filter(t => t.locale_id !== this.localeId)
          }));
          this.UPDATE_QUESTION_CHOICE_TRANS(choiceTranslation);
        }
      } 
    },
    ...mapMutations('questions',
      [
        'UPDATE_QUESTION',
        'UPDATE_QUESTION_TRANS',
        'UPDATE_QUESTION_CHOICE_TRANS',
        'ADD_MATCHED_CHOICES',
        'REMOVE_MANY_MATCHED_CHOICES'
      ]
    )
  },
  setup() {
    return {
      getValidationState,
      roleAlias,
      userRoles
    };
  },
};
</script>

 

<style scoped>
  .fl-r{
    float: right;
  }
  .match-link-button:focus {
    background-color: rgba(205, 222, 135, 1) !important;
    border-color: rgba(205, 222, 135, 1) !important;
  }
  .btn-primary.active {
    background-color: rgba(122, 150, 5, 1) !important;
    border-color: rgba(122, 150, 5, 1) !important;
  }
  .btn-bg-light {
    background-color: #f6f6f6 !important;
    border-color: rgb(225, 216, 216) !important;
  }

  .btn-bg-light.active {
    background-color: #d4d0d0 !important;
    border-color: rgb(198, 190, 190) !important;
  }
</style>